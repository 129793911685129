import React from "react";
import { Field } from "react-final-form";
import StarStroke from "../../svg/StarStroke";
import StarSolid from "../../svg/StarSolid";
import { clearText } from "../SharedInternationalizations";
import SVGXKnockoutIcon from "../../svg/XKnockoutIcon";

function RatingStarsInput(props) {
  const { rating = 0, name, starsNumber = 5 } = props;

  function starDisplay() {
    const stars = [];
    for (let index = 1; index < starsNumber + 1; index++) {
      stars.unshift(
        <label className="form__rating-stars-item cursor-pointer" key={index}>
          <Field name={name} type="radio" value={index} data-test="star-field">
            {({ input }) => (
              <>
                <input
                  className="visually-hidden"
                  name={input.name}
                  type="radio"
                  value={input.value}
                  checked={input.checked}
                  onChange={input.onChange}
                />
              </>
            )}
          </Field>
          {rating > index - 1 ? (
            <StarSolid data-test="star-solid" />
          ) : (
            <StarStroke data-test="star-stroke" />
          )}
        </label>
      );
    }
    return stars;
  }

  return (
    <div className="form__rating-stars" data-test="form-rating-stars">
      <label className=" form__rating-stars-item--clear form__rating-stars-item">
        <SVGXKnockoutIcon />
        <Field name={name} type="radio" value="0" data-test="zero">
          {({ input }) => (
            <>
              <input
                className="visually-hidden"
                name={input.name}
                type="radio"
                value={0}
                checked={input.checked}
                onChange={input.onChange}
              />
            </>
          )}
        </Field>
      </label>
      <div className="form__rating-stars-container">{starDisplay()}</div>
    </div>
  );
}

export default RatingStarsInput;
