import store from "./store";
import { apiQueueAdd } from "./redux/actions/apiActions";
import { refreshAuthtoken } from "./redux/actions/authActions";

export function checkTokenStatus(request) {
  const authToken = store.store.getState().authToken;

  if (authToken.tokenRefreshing === true) {
    // add to queue
    store.store.dispatch(apiQueueAdd(request));
  } else {
    const tokenExpiration = new Date(authToken.tokenExpiration);
    const refreshTokenExpiration = new Date(authToken.refreshTokenExpiration);
    const currentDate = Date.now();
    // refresh token expires 1209600 sec after created, auth token expires 300 sec after creation, take 1209600, subtract 300, multiply by 1000 to convert to miliseconds, add to expiration and you get the date/time of when the refresh token expires
    const timeToTokenExpire = tokenExpiration - currentDate;
    const timeToRefreshTokenExpire = refreshTokenExpiration - currentDate;

    if (timeToTokenExpire < 10000 && timeToRefreshTokenExpire > 20000) {
      store.store.dispatch(refreshAuthtoken());
      // add request to queue
      store.store.dispatch(apiQueueAdd(request));
    } else {
      //submit request
      store.store.dispatch({ ...request });
    }
  }
}

/**
 * Generic sorting functon
 * @param {array} data - The array of data to be sorted
 * @param {string} sortParam - The parameter to sort the data by
 * @param {string} sortOrder - The order to sort by A -> Z or Z -> A
 * @returns {array} - The sorted data
 */

export function sort(data, sortParam, sortOrder) {
  function checkIfLetters(value) {
    const regex = RegExp("^d");
    return regex.test(value);
  }
  let sortedData;

  if (sortOrder === "az") {
    sortedData = data.sort(function(a, b) {
      let aParam = a[sortParam];
      let bParam = b[sortParam];

      const aIsLetters = checkIfLetters(aParam);
      const bIsLetters = checkIfLetters(bParam);
      if (typeof aParam === "string" && aIsLetters && bIsLetters) {
        aParam = aParam.toUpperCase(); // ignore upper and lowercase
        bParam = bParam.toUpperCase(); // ignore upper and lowercase
      }

      if (aParam < bParam) {
        return -1;
      }
      if (aParam > bParam) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  } else if (sortOrder === "za") {
    sortedData = data.sort(function(a, b) {
      let aParam = a[sortParam];
      let bParam = b[sortParam];

      const aIsLetters = checkIfLetters(aParam);
      const bIsLetters = checkIfLetters(bParam);
      if (typeof aParam === "string" && aIsLetters && bIsLetters) {
        aParam = aParam.toUpperCase(); // ignore upper and lowercase
        bParam = bParam.toUpperCase(); // ignore upper and lowercase
      }

      if (aParam < bParam) {
        return 1;
      }
      if (aParam > bParam) {
        return -1;
      }
      // names must be equal
      return 0;
    });
  }
  return sortedData;
}
