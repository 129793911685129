import {
  DELETE_MAPVIEW,
  REMOVE_MAPVIEW,
  GET_MAPVIEWS,
  ADD_MAPVIEW,
  PROCESS_RETURNED_MAPVIEWS,
  PROCESS_NEW_MAPVIEW,
  insertMapView,
  loadMapViews
} from "../actions/mapViewsActions";
import { apiRequest } from "../actions/apiActions";
import { showLoading, hideLoading } from "../actions/uiActions";

const getMapViews = store => next => action => {
  next(action);
  if (action.type === GET_MAPVIEWS) {
    const sessionToken = store.getState().sessionToken;
    const token = store.getState().authToken.authToken;
    const body = null;
    const meta = {
      method: "GET",
      url: "entity/view/user_map_views?_format=json",
      onError: "",
      endPointType: "rest",
      authToken: token,
      onSuccess: PROCESS_RETURNED_MAPVIEWS,
      sessionToken,
      body: false
    };
    store.dispatch(showLoading());
    store.dispatch(apiRequest(body, meta));
  }
};

const addMapView = store => next => action => {
  next(action);
  if (action.type === ADD_MAPVIEW) {
    const { lat, lng, zoomLevel, title } = action.payload;

    const token = store.getState().authToken.authToken;
    const body = {
      data: {
        type: "node--map_view",
        attributes: {
          langcode: "en",
          title: title,
          field_zoom_level: zoomLevel,
          field_lat_lng: {
            lat: lat,
            lng: lng
          }
        }
      }
    };
    const meta = {
      method: "POST",
      url: "jsonapi/node/map_view/",
      body: true,
      endPointType: "json",
      authToken: token,
      onSuccess: PROCESS_NEW_MAPVIEW,
      onError: "",
      bodyType: "json"
    };
    store.dispatch(showLoading());
    store.dispatch(apiRequest(body, meta));
  }
};

const deleteMapView = store => next => action => {
  next(action);
  if (action.type === DELETE_MAPVIEW) {
    const token = store.getState().authToken.authToken;
    const body = "";
    const meta = {
      method: "DELETE",
      url: "jsonapi/node/map_view/" + action.payload,
      body: false,
      endPointType: "json",
      authToken: token,
      onSuccess: REMOVE_MAPVIEW,
      onError: "",
      uid: action.payload
    };
    store.dispatch(showLoading());
    store.dispatch(apiRequest(body, meta));
  }
};
const processDeleteMapView = store => next => action => {
  next(action);
  if (action.type === REMOVE_MAPVIEW) {
    store.dispatch(hideLoading());
  }
};

const processNewMapView = store => next => action => {
  next(action);
  if (action.type === PROCESS_NEW_MAPVIEW) {
    const mapView = action.payload.data;
    const mapViewInfo = mapView.attributes;

    const newMapView = {
      title: mapViewInfo.title,
      zoomLevel: mapViewInfo.field_zoom_level,
      lat: mapViewInfo.field_lat_lng.lat,
      lng: mapViewInfo.field_lat_lng.lng,
      uid: mapView.id,
      revision: mapViewInfo.revision_timestamp
    };
    store.dispatch(hideLoading());
    store.dispatch(insertMapView(newMapView));
  }
};

const processReturnedMapViews = store => next => action => {
  next(action);
  if (action.type === PROCESS_RETURNED_MAPVIEWS) {
    const apiData = action.payload;
    let processedMapViews = store.getState().mapViews;

    apiData.map(mapView => {
      // check to see if the view exists already in the store by checking the uuid

      // if it does exist...
      if (processedMapViews[mapView.uuid[0].value]) {
        const previousDate = new Date(
          processedMapViews[mapView.uuid[0].value].revision
        );
        const newDate = new Date(mapView.revision_timestamp[0].value);
        // compare the dates
        if (previousDate < newDate) {
          // if the new view date is newer than the old one, replace the old with the new
          let viewName = "Map View";
          let zoomLevel = 10;
          if (mapView.title[0]) {
            viewName = mapView.title[0].value;
          }
          if (mapView.field_zoom_level[0]) {
            zoomLevel = mapView.field_zoom_level[0].value;
          }

          const newMapView = {
            title: viewName,
            lat: mapView.field_lat_lng[0].lat,
            lng: mapView.field_lat_lng[0].lng,
            zoomLevel: zoomLevel,
            uid: mapView.uuid[0].value,
            revision: mapView.revision_timestamp[0].value
          };
          processedMapViews[newMapView.uid] = newMapView;
        }
      } else {
        // if it doesn't exist, create the view
        let viewName = "Map View";
        let zoomLevel = 10;
        if (mapView.title[0]) {
          viewName = mapView.title[0].value;
        }
        if (mapView.field_zoom_level[0]) {
          zoomLevel = mapView.field_zoom_level[0].value;
        }

        const newMapView = {
          title: viewName,
          lat: mapView.field_lat_lng[0].lat,
          lng: mapView.field_lat_lng[0].lng,
          zoomLevel: zoomLevel,
          uid: mapView.uuid[0].value,
          revision: mapView.revision_timestamp[0].value
        };
        processedMapViews[newMapView.uid] = newMapView;
      }
      return "";
    });
    store.dispatch(hideLoading());
    store.dispatch(loadMapViews(processedMapViews));
  }
};

export const mapViewsMdl = [
  getMapViews,
  addMapView,
  processNewMapView,
  processReturnedMapViews,
  deleteMapView,
  processDeleteMapView
];
