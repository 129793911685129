import React from "react";

const SvgOpenExternalIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M38.31 49.3l30.525-30.525 12.459 12.46L50.768 61.76z" />
    <path d="M98.86 1.22l-43.3 4.31 38.99 38.98zM93.03 50v31.08c0 6.6-5.35 11.95-11.95 11.95H18.92c-6.6 0-11.95-5.35-11.95-11.95V18.92c0-6.6 5.35-11.95 11.95-11.95H50v10.48H29.4c-6.6 0-11.95 5.35-11.95 11.95v41.2c0 6.6 5.35 11.95 11.95 11.95h41.2c6.6 0 11.95-5.35 11.95-11.95V50h10.48z" />
  </svg>
);

export default SvgOpenExternalIcon;
