export const SHOW_LOADING = "[ui] show loading";
export const HIDE_LOADING = "[ui] hide loading";
export const REGISTER_MSG = "[ui] register message";
export const CLEAR_LOGIN_MSG = "[ui] clear login message";
export const CLEAR_UI_MSG = "[ui] ui message cleared";
export const ADD_UI_MSG = "[ui] ui message added";

export const showLoading = () => ({
  type: SHOW_LOADING
});

export const hideLoading = () => ({
  type: HIDE_LOADING
});

export const registerMsg = message => ({
  type: REGISTER_MSG,
  message
});

export const clearLoginMsg = () => ({
  type: CLEAR_LOGIN_MSG
});

export const clearUiMsg = messageId => ({
  type: CLEAR_UI_MSG,
  payload: messageId
});

export const addUiMsg = message => ({
  type: ADD_UI_MSG,
  payload: message
});
