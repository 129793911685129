import React from "react";
import MapViewsList from "./MapViewsList";
import AddMapPointButton from "./AddMapPointButton";
import UserLocation from "./UserLocation";

import UserAccountMenu from "./UserAccountMenu";

function MainSidebarMenu(props) {
  const {sidebarStatus, setSidebarStatus } = props

  return (
    <React.Fragment>
      <UserLocation />
      <AddMapPointButton />
      <hr />
      <MapViewsList  sidebarStatus={sidebarStatus} setSidebarStatus={setSidebarStatus} />
      <hr />
      <UserAccountMenu  sidebarStatus={sidebarStatus} setSidebarStatus={setSidebarStatus} />
    </React.Fragment>
  );
}

export default MainSidebarMenu;
