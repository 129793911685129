import { API_QUEUE_ADD, API_QUEUE_UPDATE } from "../actions/apiActions";

function apiQueue(state = [], action) {
  switch (action.type) {
    case API_QUEUE_ADD:
      const queue = [...state];
      queue.push(action.request);
      return [...queue];

    case API_QUEUE_UPDATE:
      return action.payload;

    default:
      return state;
  }
}

export default apiQueue;
