import React, { useState } from "react";
import LogoutButton from "./LogoutButton";
import SidebarMainButton from "../sidebarShared/SidebarMainButton";
import SidebarExpander from "../sidebarShared/SidebarExpander";
import UserAccountButton from "./UserAccountButton";
import { userAccountText } from "../shared/SharedInternationalizations";
import SvgThinArrowKnockoutIcon from "../svg/ThinArrowKnockoutIcon";
import SvgUserKnockoutIcon from "../svg/UserKnockoutIcon";

function UserAccountMenu(props) {
  const {sidebarStatus, setSidebarStatus } = props
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    if (!sidebarStatus) {
      setSidebarStatus(true);
    }
    setExpanded(!expanded);
  }
  return (
    <React.Fragment>
      <SidebarMainButton
        leftIcon={<SvgUserKnockoutIcon />}
        title={userAccountText}
        rightIcon={<SvgThinArrowKnockoutIcon />}
        rightIconStyles={expanded ? "rotate-180-cw" : ""}
        onClick={toggleExpanded}
      />
      <SidebarExpander isExpanded={expanded}>
        {/* <UserAccountButton /> */}
        <LogoutButton />
      </SidebarExpander>
    </React.Fragment>
  );
}

export default UserAccountMenu;
