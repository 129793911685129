import React from "react";

/*
Props:
leftIcon = component
title = string
rightIcon = component
rightIconRotated = bool
onClick = function
*/

function SidebarMainButton(props) {
  const {
    leftIcon,
    title,
    rightIcon,
    leftIconStyles,
    onClick,
    rightIconStyles,
    calloutButton,
    additionalStyles = ""
  } = props;

  const calloutStyle = calloutButton
    ? " sidebar-main-button__main-container--callout-button"
    : "";

  const mainStyles =
    "sidebar-main-button__main-container cursor-pointer " +
    additionalStyles +
    calloutStyle;

  return (
    <button
      data-test="sidebar-main-button"
      className={mainStyles}
      onClick={onClick}
      type="button"
    >
      <div className="sidebar-main-button__title-container">
        <div
          data-test="left-icon"
          className={
            "sidebar-main-button__left-icon-container " + leftIconStyles
          }
        >
          {leftIcon}
        </div>
        <span
          className="sidebar-main-button__title text-large"
          data-test="title"
        >
          {title}
        </span>
      </div>
      <div
        data-test="right-icon"
        className={
          "sidebar-main-button__right-icon-container " + rightIconStyles
        }
      >
        {rightIcon}
      </div>
    </button>
  );
}

export default SidebarMainButton;
