import { UPDATE_LANGUAGE, ADD_LANGUAGES } from "../actions/languagesActions";

/**
 * Reducer to update the site language
 * @param {object} state
 * @param {object} action
 */
function multilingual(
  state = {
    languages: [],
    selectedLanguage: "en",
    languageChanged: false
  },
  action
) {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      // This updates the set language to the one chosen in the interface or set by the browser
      return {
        ...state,
        selectedLanguage: action.language,
        languageChanged: true
      };
    case ADD_LANGUAGES:
      // This takes the list of available translations and loads them into the store
      return {
        ...state,
        languages: action.languages
      };

    default:
  }

  return state;
}

export default multilingual;
