import React from "react";

const SvgLinkIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M26.71 49.89l-3.68 3.68-9.76 9.76c-6.46 6.46-6.46 16.93 0 23.39 6.46 6.46 16.93 6.46 23.39 0L54.78 68.6c6.46-6.46 6.46-16.93 0-23.39a16.43 16.43 0 00-8.54-4.54l2.97-7.88c4.22 1.03 8.21 3.19 11.51 6.48 9.58 9.58 9.58 25.12 0 34.7L42.03 92.66c-9.58 9.58-25.12 9.58-34.7 0-9.58-9.58-9.58-25.12 0-34.7l9.84-9.84 4.17-4.17 5.37 5.94z" />
    <path d="M73.79 49.6l3.17-3.17 9.76-9.76c6.46-6.46 6.46-16.93 0-23.39-6.46-6.46-16.93-6.46-23.39 0L45.22 31.39c-6.46 6.46-6.46 16.93 0 23.39a16.43 16.43 0 008.54 4.54l-2.97 7.88a24.472 24.472 0 01-11.51-6.48c-9.58-9.58-9.58-25.12 0-34.7L57.97 7.34c9.58-9.58 25.12-9.58 34.7 0 9.58 9.58 9.58 25.12 0 34.7l-9.84 9.84-3.66 3.66-5.38-5.94z" />
  </svg>
);

export default SvgLinkIcon;
