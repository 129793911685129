import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import MapDisplay from "./MapDisplay";
import { GET_MAPPOINTS } from "../../redux/actions/mapPointActions";
import { GET_CATEGORIES } from "../../redux/actions/categoriesActions";
import { GET_MAPVIEWS } from "../../redux/actions/mapViewsActions";
import Logo from "../shared/Logo";
import MainSidebarMenu from "../sidebar/MainSidebarMenu";
import { checkTokenStatus } from "../../utilities";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet-async";
import CollapseSidebar from "../sidebar/CollapseSidebar";
import MapPointView from "../sidebar/MapPointView";
import MapPointEdit from "../sidebar/MapPointEdit";
import AddMapPoint from "../sidebar/AddMapPoint";
import { GET_CURRENT_USER_ID } from "../../redux/actions/userActions";

function Map(props) {
  useEffect(() => {
    checkTokenStatus({ type: GET_MAPPOINTS });
    checkTokenStatus({ type: GET_CATEGORIES });
    checkTokenStatus({ type: GET_MAPVIEWS });
    checkTokenStatus({ type: GET_CURRENT_USER_ID });
  }, []); // eslint-disable-line
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  const routes = [
    {
      path: "/app/map/",
      exact: true,
      sidebarTop: () => (
        <CollapseSidebar
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
        />
      ),
      sidebarMain: () => <MainSidebarMenu sidebarStatus={showSidebar} setSidebarStatus={setShowSidebar} />,
    },
    {
      path: "/app/map/point/:id/view",
      exact: true,
      sidebarMain: () => <MapPointView sidebarStatus={showSidebar} setSidebarStatus={setShowSidebar} />,
    },
    {
      path: "/app/map/point/:id/edit",
      exact: true,
      sidebarMain: () => <MapPointEdit sidebarStatus={showSidebar} setSidebarStatus={setShowSidebar} />,
    },
    {
      path: "/app/map/point/new",
      exact: true,
      sidebarMain: () => <AddMapPoint sidebarStatus={showSidebar} setSidebarStatus={setShowSidebar} />,
    },
  ];
  return (
    <main className="app">
      <FormattedMessage
        id="map.browserTabInfo"
        defaultMessage="My Vacation Map | Map"
        description="">
        {txt => (
          <Helmet>
            <title>{txt}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <div
        className={
          showSidebar
            ? "sidebar__container sidebar__container--open"
            : "sidebar__container"
        }>
        <div className="sidebar__sub-container sidebar__top-container">
          <Logo />
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.sidebarTop}
            />
          ))}
        </div>
        <hr />
        <div className="sidebar__sub-container sidebar__main-container">
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.sidebarMain}
            />
          ))}
        </div>
      </div>
      <MapDisplay />
    </main>
  );
}
const mapStateToProps = state => {
  return {
    mapviews: state.mapviews,
    mapPoints: state.mapPoints,
  };
};
export const MapWithRouter = withRouter(Map);
export default connect(mapStateToProps)(Map);
