import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { getCurrentUserInfo } from "../../redux/actions/userActions";
import { newMapPoint } from "../../redux/actions/mapPointActions";

function Account(props) {
  useEffect(() => {
    // props.dispatch(getCurrentUserInfo());
  }, []); // eslint-disable-line

  return (
    <div>
      <FormattedMessage
        id="account.browserTabInfo"
        defaultMessage="My Vacation Map | Account"
        description=""
      >
        {txt => (
          <Helmet>
            <title>{txt}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <Link to="/app/map">Map</Link>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    currentUserId: state.userInfo.uid,
    points: state.mapPoints
  };
};

export default connect(mapStateToProps)(Account);
