import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import SidebarExpander from "../sidebarShared/SidebarExpander";
import {
  pointNameText,
  notesText,
  descriptionText,
  latitudeText,
  LongitudeText,
  categoryText,
  cancelText,
  saveText,
  moreOptionsText,
  linksText,
  titleText,
  urlText,
  addLinkText,
  ratingText
} from "../shared/SharedInternationalizations";
import SvgThinArrowKnockoutIcon from "../svg/ThinArrowKnockoutIcon";
import SvgDashKnockoutIcon from "../svg/DashKnockoutIcon";
import SVGPlusKnockoutIcon from "../svg/PlusKnockoutIcon";
import RatingStarsInput from "../shared/form/RatingStarsInput";
import SidebarMainButton from "../sidebarShared/SidebarMainButton";
import {
  setEditPointLocation,
  setEditPointActive
} from "../../redux/actions/editItemActions";
const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export function UnconnectedAddEditMapPointForm(props) {
  const { onSubmit, pointInfo, categories, link, editItemInformation } = props;

  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState(false);
  const [location, setLocation] = useState({
    lat: pointInfo.lat,
    lng: pointInfo.lng
  });
  function toggleExpanded() {
    setExpanded(!expanded);
  }

  useEffect(() => {
    const initialLocation = {
      lat: pointInfo.lat,
      lng: pointInfo.lng,
      pointId: pointInfo.uid || null
    };
    props.dispatch(setEditPointActive(true));
    props.dispatch(setEditPointLocation(initialLocation));
  }, []); // eslint-disable-line

  useEffect(() => {
    return () => {
      props.dispatch(setEditPointActive(false));
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (active) {
      setLocation({
        lat: editItemInformation.lat,
        lng: editItemInformation.lng
      });
      const newLocation = {
        lat: editItemInformation.lat,
        lng: editItemInformation.lng,
        pointId: pointInfo.uid || null
      };
      props.dispatch(setEditPointLocation(newLocation));
    }
    setActive(false);
  }, [editItemInformation]); // eslint-disable-line

  function categoryOptionsDisplay() {
    const categoriesArr = Object.values(categories);
    const options = categoriesArr.map(cat => {
      return (
        <option value={cat.uid} key={cat.uid} data-test="category-option">
          {cat.name}
        </option>
      );
    });
    return options;
  }

  function setPointLocation() {
    if (!active) {
      setActive(true);
    }
  }

  const linkStartValidator = RegExp("^https?://(.*)", "i");
  const urlRequired = value =>
    linkStartValidator.test(value)
      ? undefined
      : 'not a valid url. Please add "http://" or "https://"';

  let pointData = { lat: 2.3210763931274, lng: 48.865406426216 };
  if (pointInfo) {
    pointData = { ...pointInfo };
  }

  const point = {
    ...pointData,
    lat: location.lat,
    lng: location.lng
  };

  return (
    <div>
      <Form
        data-test="add-edit-map-point-form"
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          }
        }}
        keepDirtyOnReinitialize={true}
        initialValues={{
          ...point
        }}
        render={({
          handleSubmit,
          pristine,
          invalid,
          reset,
          submitting,
          values,
          form: {
            mutators: { push, pop, setValue }
          } // inj
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <SidebarMainButton
                leftIcon={<SVGPlusKnockoutIcon />}
                title="Set Point Location"
                onClick={setPointLocation}
                calloutButton
                additionalStyles={
                  active
                    ? "margin-top-10 margin-bottom-10 button__success"
                    : "margin-top-10 margin-bottom-10"
                }
              />
              <button
                onClick={toggleExpanded}
                className="button__text-button align-center flex-center width-100 text-small"
                type="button"
              >
                {moreOptionsText}
                <SvgThinArrowKnockoutIcon
                  className={
                    expanded
                      ? "transition-item rotate-180-cw margin-left-10"
                      : "margin-left-10 transition-item"
                  }
                />
              </button>
              <SidebarExpander isExpanded={expanded}>
                <div className="form__section-container">
                  <div className="form__form-item">
                    <label>{latitudeText}</label>
                    <Field name="lat" component="input" />
                  </div>
                  <div className="form__form-item">
                    <label>{LongitudeText}</label>
                    <Field name="lng" component="input" />
                  </div>
                </div>
                <hr />
              </SidebarExpander>
              <div className="form__section-container">
                <div className="form__form-item">
                  <label>{pointNameText}</label>
                  <Field name="name" component="input" />
                </div>
                <div className="form__form-item">
                  <label>{categoryText}</label>
                  <Field
                    className="form-item__field"
                    name="category"
                    component="select"
                  >
                    <option></option>
                    {categoryOptionsDisplay()}
                  </Field>
                </div>
                <div className="form__form-item">
                  <label>{descriptionText}</label>
                  <Field name="description" component="textarea" />
                </div>
                <div className="form__form-item">
                  <label>{notesText}</label>
                  <Field name="notes" component="textarea" />
                </div>

                <label>{linksText}</label>

                <FieldArray name="links">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <React.Fragment key={name}>
                        {index === 0 ? <hr /> : ""}
                        <div className="form__link-group-container" key={name}>
                          <div className="form__link-group">
                            <div className="form__form-item">
                              <label>{titleText}</label>
                              <Field name={`${name}.title`} component="input" />
                            </div>
                            <div className="form__form-item">
                              <label className="form-item__required">
                                {urlText}
                              </label>
                              <Field
                                name={`${name}.uri`}
                                component="input"
                                required
                                validate={urlRequired}
                              />
                            </div>
                          </div>
                          <div className="form__link-remove-button">
                            <button
                              className="button__text-button button__close-button-icon"
                              onClick={() => fields.remove(index)}
                              value={index}
                              type="button"
                            >
                              <SvgDashKnockoutIcon />
                            </button>
                          </div>
                        </div>
                        <hr />
                      </React.Fragment>
                    ))
                  }
                </FieldArray>
                <button
                  data-test="add-link-button"
                  type="button"
                  onClick={() => push("links", undefined)}
                  className="button__text-button button__close-button-icon align-center flex-center"
                >
                  {addLinkText}{" "}
                  <SVGPlusKnockoutIcon className="margin-left-5" />
                </button>
                <div className="form__form-item">
                  <label>{ratingText}</label>
                  <RatingStarsInput
                    rating={values.rating}
                    name="rating"
                    starsNumber={5}
                  />
                </div>
              </div>

              <div className="form__section-container form__section-container--side-by-side">
                <Link
                  className="width-50 text-center"
                  to={link}
                  data-test="add-user-form-cancel-link"
                >
                  {cancelText}
                </Link>

                <button
                  type="submit"
                  // disabled={pristine || invalid}
                  className="button__general-button"
                  data-test="add-user-form-submit"
                >
                  {saveText}
                </button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const currentView = state.currentMapView;

  let pointInfo = { lat: currentView.lat, lng: currentView.lng };
  if (ownProps.id) {
    pointInfo = { ...state.mapPoints[ownProps.id] };
  }

  return {
    pointInfo,
    categories: state.categories,
    editItemInformation: state.editItemInformation
  };
};

export default connect(mapStateToProps)(UnconnectedAddEditMapPointForm);

// validate={required}
