import { ADD_SESSIONTOKEN } from "../actions/sessionTokenActions";

function sessionToken(state = "", action) {
  switch (action.type) {
    case ADD_SESSIONTOKEN:
      return action.payload;

    default:
  }

  return state;
}

export default sessionToken;
