import React, { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { connect } from "react-redux";
import {
  setUserLocation,
  useUserLocation as userLocation
} from "../../redux/actions/userLocationActions";
import { setCurrentMapView } from "../../redux/actions/currentMapViewActions";
import SvgStarPointKnockoutIcon from "../svg/StarPointKnockoutIcon";
import SvgXKnockoutIcon from "../svg/XKnockoutIcon";
import SvgTargetIcon from "../svg/TargetIcon";
import SidebarMainButton from "../sidebarShared/SidebarMainButton";

function UserLocation(props) {
  // used to show/hide tracking buttons
  const [tracking, setTracking] = useState(false);

  const { useUserLoc, mapView } = props;
  console.log({ useUserLoc });
  // get user location
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      watchPosition: true,
      userDecisionTimeout: 5000,
    });


  function startUseUserLocation() {
    props.dispatch(userLocation(true));
  }

  function stopUseUserLocation() {
    props.dispatch(userLocation(false));
  }

  function goToLocation() {
    props.dispatch(
      setCurrentMapView({
        lat: coords.latitude,
        lng: coords.longitude,
        zoomLevel: mapView.zoomLevel
      })
    );
  }

  if (coords) {
    props.dispatch(
      setUserLocation({
        lat: coords.latitude,
        lng: coords.longitude
      })
    );
  }

  return isGeolocationAvailable ? !isGeolocationEnabled ? (
    <div>Geolocation isn't enabled</div>
  ) : coords ? (
    <React.Fragment>
      <SidebarMainButton
        leftIcon={<SvgStarPointKnockoutIcon />}
        title={"Go To Current Location"}
        onClick={goToLocation}
      />
      {useUserLoc ? <SidebarMainButton
        leftIcon={<SvgXKnockoutIcon />}
        title={"Stop Tracking Location"}
        onClick={stopUseUserLocation}
      /> : <SidebarMainButton
        leftIcon={<SvgTargetIcon />}
        title={"Track Current Location"}
        onClick={startUseUserLocation}
      />}
    <hr/>
    </React.Fragment>
  ) : (
    <div>Getting the location data&hellip; </div>
  ): {};

}

const mapStateToProps = (state, ownProps) => {
  return {
    useUserLoc: state.userLocation.useUserLocation,
    mapView: state.currentMapView
  };
};

export default connect(mapStateToProps)(UserLocation);
