import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  passwordText,
  emailText,
  cancelText,
  firstNameText,
  lastNameText
} from "../shared/SharedInternationalizations";

function RegisterForm(props) {
  const {
    regFirstNameRef,
    regLastNameRef,
    regEmailRef,
    regPasswordRef,
    onSubmit
  } = props;

  const registerNewUserText = (
    <FormattedMessage
      id="login.registerNewUserText"
      defaultMessage="Register A New User"
      description=""
    />
  );

  const createAccountText = (
    <FormattedMessage
      id="login.createAccountText"
      defaultMessage="Create Account"
      description=""
    />
  );

  return (
    <React.Fragment>
      <form
        id="registerForm"
        className="login__login-form"
        role="search"
        onSubmit={onSubmit}
        data-test="register-form"
      >
        <h2>{registerNewUserText}</h2>
        <label htmlFor="reg_first_name" className="formLabel">
          {firstNameText}
        </label>
        <input
          id="first_name"
          className="login__login-form-field"
          required
          ref={regFirstNameRef}
          data-test="first-name-field"
        />

        <label htmlFor="reg_last_name" className="formLabel">
          {lastNameText}
        </label>
        <input
          id="last_name"
          className="login__login-form-field"
          required
          ref={regLastNameRef}
          data-test="last-name-field"
        />

        <label htmlFor="reg_login_name" className="formLabel">
          {emailText}
        </label>
        <input
          type="email"
          id="login_name"
          className="login__login-form-field"
          required
          ref={regEmailRef}
          data-test="email-field"
        />

        <label htmlFor="login_pw" className="formLabel">
          {passwordText}
        </label>
        <input
          type="password"
          name="password"
          id="login_pw"
          className="login__login-form-field"
          required
          ref={regPasswordRef}
          data-test="password-field"
        />
        <div className="login__login-form-button-container">
          <div className="flex-center align-center login__login-form-text-button">
            <Link to="/login">{cancelText}</Link>
          </div>
          <button
            className="button__general-button"
            type="submit"
            data-test="register-submit"
          >
            {createAccountText}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
}

export default RegisterForm;
