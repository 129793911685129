import React from "react";
import { connect } from "react-redux";
import SidebarMainLink from "../sidebarShared/SidebarMainLink";
import SvgPlusKnockoutIcon from "../svg/PlusKnockoutIcon";

function AddMapPointButton() {
  return (
    <React.Fragment>
      <SidebarMainLink
        leftIcon={<SvgPlusKnockoutIcon />}
        title="Add Map Point"
        link="/app/map/point/new"
      />
    </React.Fragment>
  );
}

export default connect()(AddMapPointButton);
