import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Logo from "./shared/Logo";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";

const SplashScreen = props => {
  const today = new Date();
  const year = today.getFullYear();
  function redirect() {
    if (props.authToken.loggedIn) {
      return <Redirect to="/app/map" />;
    } else {
      return <Redirect to="/login" />;
    }
  }

  return (
    <div className="login__container">
      <FormattedMessage
        id="spashscreen.browserTabInfo"
        defaultMessage="My Vacation Map"
        description=""
      >
        {txt => (
          <Helmet>
            <title>{txt}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <div className="login__content-container">
        <Logo />
      </div>
      <div className="footer">
        <p>
          © {year} Rob Lahoda •
          <a href="mailto:rob@roblahoda.com">rob@roblahoda.com</a> •
          <a href="https://www.roblahoda.com">roblahoda.com</a>
        </p>
      </div>
      {redirect()}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    sessionToken: state.sessionToken,
    authToken: state.authToken
  };
};

export default connect(mapStateToProps)(SplashScreen);
