import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet-async";
import { passwordText, emailText } from "../shared/SharedInternationalizations";

function LoginForm(props) {
  const { emailRef, passwordRef, onSubmit } = props;

  const createAccountText = (
    <FormattedMessage
      id="login.createAccountText"
      defaultMessage="Create Account"
      description=""
    />
  );
  const loginText = (
    <FormattedMessage
      id="login.loginText"
      defaultMessage="Log In"
      description=""
    />
  );

  return (
    <React.Fragment>
      <FormattedMessage
        id="login.browserTabInfo"
        defaultMessage="My Vacation Map | Login"
        description=""
      >
        {txt => (
          <Helmet>
            <title>{txt}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <form
        id="loginForm"
        className="login__login-form"
        role="search"
        onSubmit={onSubmit}
        data-test="login-form"
      >
        <label htmlFor="login_name" className="formLabel">
          {emailText}
        </label>
        <input
          type="email"
          id="login_name"
          className="login__login-form-field"
          required
          ref={emailRef}
          data-test="email-field"
        />

        <label htmlFor="login_pw" className="formLabel">
          {passwordText}
        </label>
        <input
          type="password"
          name="password"
          id="login_pw"
          className="login__login-form-field"
          required
          ref={passwordRef}
          data-test="password-field"
        />
        <div className="login__login-form-button-container">
          <div className="flex-center align-center login__login-form-text-button">
            <Link className="" to="/register">
              {createAccountText}
            </Link>
          </div>
          <button
            className="button__general-button"
            type="submit"
            data-test="login-submit"
          >
            {loginText}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
}

export default LoginForm;
