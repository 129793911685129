import {
  GET_CATEGORIES,
  PROCESS_RETURNED_CATEGORIES,
  insertCategory,
  loadCategories
} from "../actions/categoriesActions";
import { apiRequest } from "../actions/apiActions";
import { iconArray } from "../../assets/MapPointIcons";
import { showLoading } from "../actions/uiActions";

const getCategories = store => next => action => {
  next(action);
  if (action.type === GET_CATEGORIES) {
    const token = store.getState().authToken.authToken;
    const body = null;
    const meta = {
      method: "GET",
      url: "jsonapi/taxonomy_term/categories/",
      endPointType: "json",
      body: false,
      onSuccess: PROCESS_RETURNED_CATEGORIES,
      onError: "",
      authToken: token
    };

    store.dispatch(apiRequest(body, meta));
  }
};

const processReturnedCategories = store => next => action => {
  next(action);
  if (action.type === PROCESS_RETURNED_CATEGORIES) {
    let processedCategories = store.getState().categories;
    const apiData = action.payload.data;

    if (action.payload.links.next) {
      const token = store.getState().authToken.authToken;
      const body = null;
      const meta = {
        method: "GET",
        url: action.payload.links.next.href,
        body: false,
        onSuccess: PROCESS_RETURNED_CATEGORIES,
        onError: "",
        endPointType: "json",
        authToken: token,
        fullUrl: true
      };
      store.dispatch(showLoading());
      store.dispatch(apiRequest(body, meta));
    }

    apiData.map(category => {
      if (processedCategories[category.id]) {
        const previousDate = new Date(processedCategories[category.id].changed);
        const newDate = new Date(category.changed);
        if (previousDate < newDate) {
          const newCategory = {
            name: category.attributes.name,
            uid: category.id,
            changed: category.attributes.changed
          };
          processedCategories[category.id] = newCategory;
        }
      } else {
        const newCategory = {
          name: category.attributes.name,
          uid: category.id,
          changed: category.attributes.changed
        };
        processedCategories[category.id] = newCategory;
      }
      return "";
    });
    store.dispatch(loadCategories(processedCategories));
  }
};

export const categoryMdl = [getCategories, processReturnedCategories];
