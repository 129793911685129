import React from "react";

/*
Props:
children = anything
isExpanded = bool
*/

function SidebarExpander(props) {
  const { children, isExpanded } = props;
  return (
    <div
      data-test="sidebar-expander"
      className={
        isExpanded
          ? "sidebar-expander__main-container  sidebar-expander__main-container--expanded"
          : "sidebar-expander__main-container"
      }
    >
      {children}
    </div>
  );
}

export default SidebarExpander;
