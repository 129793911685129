export const GET_MAPVIEWS = "[mapViews] get mapViews";
export const ADD_MAPVIEW = "[mapViews] add mapView";
export const DELETE_MAPVIEW = "[mapViews] delete mapView";
export const PROCESS_REMOVE_MAPVIEW = "[mapViews] process remove mapView";
export const REMOVE_MAPVIEW = "[mapViews] remove mapView";
export const INSERT_MAPVIEW = "[mapViews] insert mapView";
export const PROCESS_RETURNED_MAPVIEWS = "[mapViews] process returned mapViews";
export const PROCESS_NEW_MAPVIEW = "[mapViews] process new mapView";
export const LOAD_MAPVIEWS = "[mapViews] load processed mapViews";

export const deleteMapView = payload => {
  return {
    type: DELETE_MAPVIEW,
    payload
  };
};

export const processRemoveMapView = payload => {
  return {
    type: PROCESS_REMOVE_MAPVIEW,
    payload
  };
};

export const removeMapView = payload => {
  return {
    type: REMOVE_MAPVIEW,
    payload
  };
};

export const getMapViews = () => {
  return {
    type: GET_MAPVIEWS
  };
};

export const addMapView = data => {
  return {
    type: ADD_MAPVIEW,
    payload: data
  };
};
export const processReturnedMapViews = () => {
  return {
    type: PROCESS_RETURNED_MAPVIEWS
  };
};

export const processNewMapView = () => {
  return {
    type: PROCESS_NEW_MAPVIEW
  };
};

export const insertMapView = payload => {
  return {
    type: INSERT_MAPVIEW,
    payload
  };
};

export const loadMapViews = payload => {
  return {
    type: LOAD_MAPVIEWS,
    payload: payload
  };
};
