import React, { useEffect, useRef } from "react";
import XKnockoutIcon from "../svg/XKnockoutIcon";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

function Overlay(props) {
  return (
    <div className="overlay__background-container">
      <div className="overlay__main-container">
        <div className="overlay__close-button">
          <XKnockoutIcon />
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default withRouter(Overlay);
