// The iconArray is pulled into the Home component and when the categories are generated after being pulled from Drupal, the icon definitions are inserted into the category objects under the "icon": key

import L from "leaflet";

export const plusIcon = new L.Icon({
  iconUrl: "/mapIcons/plusIcon.svg",
  iconRetinaUrl: "/mapIcons/plusIcon.svg",
  iconAnchor: [20, 40],
  popupAnchor: [0, 0],

  iconSize: [40, 40]
});

export const compassRoseIcon = new L.Icon({
  iconUrl: "/mapIcons/compassRoseIcon.svg",
  iconRetinaUrl: "/mapIcons/compassRoseIcon.svg",
  iconAnchor: [15, 15],
  popupAnchor: [0, 0],

  iconSize: [30, 30]
});

export const iconObj = {
 Airport: {
    category: "Airport",
    icon: new L.Icon({
      iconUrl: "/mapIcons/airportIcon.svg",
      iconRetinaUrl: "/mapIcons/airportIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Bus:{
    category: "Bus",
    icon: new L.Icon({
      iconUrl: "/mapIcons/busIcon.svg",
      iconRetinaUrl: "/mapIcons/busIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },

  Ferry:{
    category: "Ferry",
    icon: new L.Icon({
      iconUrl: "/mapIcons/ferryIcon.svg",
      iconRetinaUrl: "/mapIcons/ferryIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Restaurant:{
    category: "Restaurant",
    icon: new L.Icon({
      iconUrl: "/mapIcons/foodIcon.svg",
      iconRetinaUrl: "/mapIcons/foodIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Hospital:{
    category: "Hospital",
    icon: new L.Icon({
      iconUrl: "/mapIcons/hospitalIcon.svg",
      iconRetinaUrl: "/mapIcons/hospitalIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Hotel:{
    category: "Hotel",
    icon: new L.Icon({
      iconUrl: "/mapIcons/bedIcon.svg",
      iconRetinaUrl: "/mapIcons/bedIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Information: {
    category: "Information",
    icon: new L.Icon({
      iconUrl: "/mapIcons/informationIcon.svg",
      iconRetinaUrl: "/mapIcons/informationIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Museum:{
    category: "Museum",
    icon: new L.Icon({
      iconUrl: "/mapIcons/museumIcon.svg",
      iconRetinaUrl: "/mapIcons/museumIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Other:{
    category: "Other",
    icon: new L.Icon({
      iconUrl: "/mapIcons/starIcon.svg",
      iconRetinaUrl: "/mapIcons/starIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Parking:{
    category: "Parking",
    icon: new L.Icon({
      iconUrl: "/mapIcons/parkingIcon.svg",
      iconRetinaUrl: "/mapIcons/parkingIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Subway:{
    category: "Subway",
    icon: new L.Icon({
      iconUrl: "/mapIcons/subwayIcon.svg",
      iconRetinaUrl: "/mapIcons/subwayIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Train:{
    category: "Train",
    icon: new L.Icon({
      iconUrl: "/mapIcons/trainIcon.svg",
      iconRetinaUrl: "/mapIcons/trainIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Tram:{
    category: "Tram",
    icon: new L.Icon({
      iconUrl: "/mapIcons/tramIcon.svg",
      iconRetinaUrl: "/mapIcons/tramIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Viewpoint:{
    category: "Viewpoint",
    icon: new L.Icon({
      iconUrl: "/mapIcons/cameraIcon.svg",
      iconRetinaUrl: "/mapIcons/cameraIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Grocery:{
    category: "Grocery",
    icon: new L.Icon({
      iconUrl: "/mapIcons/groceryIcon.svg",
      iconRetinaUrl: "/mapIcons/groceryIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Shopping:{
    category: "Shopping",
    icon: new L.Icon({
      iconUrl: "/mapIcons/shoppingIcon.svg",
      iconRetinaUrl: "/mapIcons/shoppingIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Swimming:{
    category: "Swimming",
    icon: new L.Icon({
      iconUrl: "/mapIcons/swimmingIcon.svg",
      iconRetinaUrl: "/mapIcons/swimmingIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Church: {
    category: "Church",
    icon: new L.Icon({
      iconUrl: "/mapIcons/churchIcon.svg",
      iconRetinaUrl: "/mapIcons/churchIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Synagogue:{
    category: "Synagogue",
    icon: new L.Icon({
      iconUrl: "/mapIcons/synagogueIcon.svg",
      iconRetinaUrl: "/mapIcons/synagogueIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Mosque:{
    category: "Mosque",
    icon: new L.Icon({
      iconUrl: "/mapIcons/mosqueIcon.svg",
      iconRetinaUrl: "/mapIcons/mosqueIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Park:{
    category: "Park",
    icon: new L.Icon({
      iconUrl: "/mapIcons/parkIcon.svg",
      iconRetinaUrl: "/mapIcons/parkIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  "Amusement Park":{
    category: "Amusement Park",
    icon: new L.Icon({
      iconUrl: "/mapIcons/amusementParkIcon.svg",
      iconRetinaUrl: "/mapIcons/amusementParkIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Monument: {
    category: "Monument",
    icon: new L.Icon({
      iconUrl: "/mapIcons/monumentIcon.svg",
      iconRetinaUrl: "/mapIcons/monumentIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  "Town Square":{
    category: "Town Square",
    icon: new L.Icon({
      iconUrl: "/mapIcons/townSquareIcon.svg",
      iconRetinaUrl: "/mapIcons/townSquareIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Financial:{
    category: "Financial",
    icon: new L.Icon({
      iconUrl: "/mapIcons/moneyIcon.svg",
      iconRetinaUrl: "/mapIcons/moneyIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Tour:{
    category: "Tour",
    icon: new L.Icon({
      iconUrl: "/mapIcons/tourIcon.svg",
      iconRetinaUrl: "/mapIcons/tourIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Culture:{
    category: "Culture",
    icon: new L.Icon({
      iconUrl: "/mapIcons/cultureIcon.svg",
      iconRetinaUrl: "/mapIcons/cultureIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Library:{
    category: "Library",
    icon: new L.Icon({
      iconUrl: "/mapIcons/libraryIcon.svg",
      iconRetinaUrl: "/mapIcons/libraryIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  "Mountain or Hill":{
    category: "Mountain or Hill",
    icon: new L.Icon({
      iconUrl: "/mapIcons/mountainIcon.svg",
      iconRetinaUrl: "/mapIcons/mountainIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Cycling:{
    category: "Cycling",
    icon: new L.Icon({
      iconUrl: "/mapIcons/bikeIcon.svg",
      iconRetinaUrl: "/mapIcons/bikeIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  Town:{
    category: "Town",
    icon: new L.Icon({
      iconUrl: "/mapIcons/townIcon.svg",
      iconRetinaUrl: "/mapIcons/townIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  City:{
    category: "City",
    icon: new L.Icon({
      iconUrl: "/mapIcons/cityIcon.svg",
      iconRetinaUrl: "/mapIcons/cityIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  },
  School:{
    category: "School",
    icon: new L.Icon({
      iconUrl: "/mapIcons/schoolIcon.svg",
      iconRetinaUrl: "/mapIcons/schoolIcon.svg",
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      iconSize: [40, 40]
    })
  }
};
