import React from "react";

const SvgXKnockoutIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M50.21 0C22.6 0 .21 22.39.21 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50zm28.95 70.87l-8.07 8.07-20.87-20.87-20.87 20.87-8.07-8.07L42.15 50 21.27 29.12l8.07-8.07 20.88 20.88L71.1 21.06l8.07 8.07L58.29 50l20.87 20.87z" />
  </svg>
);

export default SvgXKnockoutIcon;
