import React, { useState } from "react";
import { connect } from "react-redux";
import SvgPlusKnockoutIcon from "../svg/PlusKnockoutIcon";
import SidebarSubButton from "../sidebarShared/SidebarSubButton";
import { FormattedMessage } from "react-intl";
import { saveText } from "../shared/SharedInternationalizations";
import { addMapView, ADD_MAPVIEW } from "../../redux/actions/mapViewsActions";
import { checkTokenStatus } from "../../utilities";

function MapViewNewViewButton(props) {
  const [showForm, setShowForm] = useState(false);
  const [fieldContents, setFieldContents] = useState("");
  const { lat, lng, zoomLevel } = props.currentMapView;

  function toggleShowForm() {
    setShowForm(!showForm);
  }

  function handleChange(event) {
    setFieldContents(event.target.value);
  }

  function submitMapView(event) {
    const view = { lat, lng, zoomLevel, title: fieldContents };
    checkTokenStatus({
      type: ADD_MAPVIEW,
      payload: view
    });

    setFieldContents("");
    setShowForm(false);
    event.preventDefault();
  }

  function displayForm() {
    if (showForm) {
      return (
        <React.Fragment>
          <div className="mapviews__new-mapview-container">
            <div className="mapviews__new-mapview-form-label">
              <FormattedMessage
                id="mapViewNewView.nameText"
                defaultMessage="Name this map view"
                description=""
              />
            </div>
            <form
              className="mapviews__new-mapview-form-field-container"
              onSubmit={submitMapView}
            >
              <input
                type="text"
                className="mapviews__new-mapview-form-field"
                onChange={handleChange}
                value={fieldContents}
              />
              <button
                type="submit"
                className="button__general-button cursor-pointer"
              >
                {saveText}
              </button>
            </form>
            <hr />
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  return (
    <React.Fragment>
      <SidebarSubButton
        leftIcon={<SvgPlusKnockoutIcon />}
        title="Save The Current Map View"
        primaryOnClick={toggleShowForm}
        calloutButton={true}
      />
      {displayForm()}
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    mapViews: Object.keys(state.mapPoints),
    currentMapView: state.currentMapView
  };
};

export default connect(mapStateToProps)(MapViewNewViewButton);
