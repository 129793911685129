import React from "react";

const SvgStarStroke = props => (
  <svg viewBox="0 0 100 100" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      d="M50 3.7l11.5 35.4h37.2L68.6 60.9l11.5 35.4L50 74.5 19.9 96.3l11.5-35.4L1.3 39.1h37.2z"
    />
  </svg>
);

export default SvgStarStroke;
