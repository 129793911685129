import {
  SET_USER_LOCATION,
  USE_USER_LOCATION
} from "../actions/userLocationActions";

function userLocation(
  state = {
    useUserLocation: false,
    location: {
      lat: 1,
      lng: 1
    }
  },
  action
) {
  switch (action.type) {
    case SET_USER_LOCATION:
      const currentLocation = action.payload;
      return {
        ...state,
        location: {
          lat: currentLocation.lat,
          lng: currentLocation.lng
        }
      };

    case USE_USER_LOCATION:
      return {
        ...state,
        useUserLocation: action.payload
      };
    default:
  }

  return state;
}

export default userLocation;
