export const GET_CATEGORIES = "[categories] get categories";
export const ADD_CATEGORY = "[categories] add category";
export const INSERT_CATEGORY = "[categories] insert category";
export const PROCESS_RETURNED_CATEGORIES =
  "[categories] process returned categories";
export const PROCESS_NEW_CATEGORY = "[categories] process new categories";
export const LOAD_CATEGORIES = "[categories] load processed categories";

export const getCategories = () => {
  return {
    type: GET_CATEGORIES
  };
};

export const addCategory = () => {
  return {
    type: ADD_CATEGORY
  };
};
export const processReturnedCategories = () => {
  return {
    type: PROCESS_RETURNED_CATEGORIES
  };
};

export const processNewCategory = () => {
  return {
    type: PROCESS_NEW_CATEGORY
  };
};

export const insertCategory = payload => {
  return {
    type: INSERT_CATEGORY,
    name: payload.name,
    uid: payload.uid
  };
};

export const loadCategories = payload => {
  return {
    type: LOAD_CATEGORIES,
    payload
  };
};
