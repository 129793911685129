import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import SidebarMainButton from "../sidebarShared/SidebarMainButton";
import SvgThickArrowKnockoutIcon from "../svg/ThickArrowKnockoutIcon";

function CollapseSidebar(props) {
  const { toggleSidebar, showSidebar } = props;

  const collapseSidebarText = (
    <FormattedMessage
      id="collapseSibar.collapseSidebarText"
      defaultMessage="Collapse Sidebar"
      description=""
    />
  );

  return (
    <React.Fragment>
      <SidebarMainButton
        leftIcon={<SvgThickArrowKnockoutIcon />}
        leftIconStyles={showSidebar ? "rotate-90-cw" : "rotate-90-ccw"}
        title={collapseSidebarText}
        onClick={toggleSidebar}
      />
    </React.Fragment>
  );
}

export default connect()(CollapseSidebar);
