import React from "react";
import { FormattedMessage } from "react-intl";

/**
 * Set of translations to be reused throughout the interface instead of repeatedly having the same words translated
 */

export const accountText = (
  <FormattedMessage
    id="shared.accountMenuText"
    defaultMessage="Account"
    description=""
  />
);
export const userAccountText = (
  <FormattedMessage
    id="shared.userAccountText"
    defaultMessage="User Account"
    description=""
  />
);
export const logOutText = (
  <FormattedMessage
    id="shared.logOutText"
    defaultMessage="Log Out"
    description=""
  />
);
export const mapViewsText = (
  <FormattedMessage
    id="shared.mapViewsText"
    defaultMessage="Map Views"
    description=""
  />
);
export const addMapViewsText = (
  <FormattedMessage
    id="shared.addMapViewsText"
    defaultMessage="Add Map View"
    description=""
  />
);
export const passwordText = (
  <FormattedMessage
    id="shared.passwordText"
    defaultMessage="Password"
    description=""
  />
);
export const emailText = (
  <FormattedMessage
    id="shared.emailText"
    defaultMessage="Email"
    description=""
  />
);
export const cancelText = (
  <FormattedMessage
    id="shared.cancelText"
    defaultMessage="Cancel"
    description=""
  />
);
export const firstNameText = (
  <FormattedMessage
    id="shared.firstNameText"
    defaultMessage="First Name"
    description=""
  />
);
export const lastNameText = (
  <FormattedMessage
    id="shared.lastNameText"
    defaultMessage="Last Name"
    description=""
  />
);
export const categoryText = (
  <FormattedMessage
    id="shared.categoryText"
    defaultMessage="Category"
    description=""
  />
);
export const descriptionText = (
  <FormattedMessage
    id="shared.descriptionText"
    defaultMessage="Description"
    description=""
  />
);
export const notesText = (
  <FormattedMessage
    id="shared.notesText"
    defaultMessage="Notes"
    description=""
  />
);
export const ratingText = (
  <FormattedMessage
    id="shared.ratingText"
    defaultMessage="Rating"
    description=""
  />
);
export const linksText = (
  <FormattedMessage
    id="shared.linksText"
    defaultMessage="Links"
    description=""
  />
);
export const viewText = (
  <FormattedMessage id="shared.viewText" defaultMessage="View" description="" />
);
export const editText = (
  <FormattedMessage id="shared.editText" defaultMessage="Edit" description="" />
);
export const deleteText = (
  <FormattedMessage
    id="shared.deleteText"
    defaultMessage="Delete"
    description=""
  />
);
export const saveText = (
  <FormattedMessage id="shared.saveText" defaultMessage="Save" description="" />
);
export const challengeText = (
  <FormattedMessage
    id="shared.challengeText"
    defaultMessage="Are you sure?"
    description=""
  />
);
export const addMapPointText = (
  <FormattedMessage
    id="shared.addMapPointText"
    defaultMessage="Add A Map Point"
    description=""
  />
);
export const setMapPointLocationText = (
  <FormattedMessage
    id="shared.setMapPointLocationText"
    defaultMessage="Set Map Point Location"
    description=""
  />
);
export const moreOptionsText = (
  <FormattedMessage
    id="shared.moreOptionsText"
    defaultMessage="More Options"
    description=""
  />
);
export const latitudeText = (
  <FormattedMessage
    id="shared.latitudeText"
    defaultMessage="Latitude"
    description=""
  />
);
export const LongitudeText = (
  <FormattedMessage
    id="shared.LongitudeText"
    defaultMessage="Longitude"
    description=""
  />
);
export const pointNameText = (
  <FormattedMessage
    id="shared.pointNameText"
    defaultMessage="Point Name"
    description=""
  />
);
export const titleText = (
  <FormattedMessage
    id="shared.titleText"
    defaultMessage="Title"
    description=""
  />
);
export const urlText = (
  <FormattedMessage id="shared.urlText" defaultMessage="Url" description="" />
);
export const createPointText = (
  <FormattedMessage
    id="shared.createPointText"
    defaultMessage="Create Point"
    description=""
  />
);
export const addLinkText = (
  <FormattedMessage
    id="shared.addLinkText"
    defaultMessage="Add Link"
    description=""
  />
);
export const clearText = (
  <FormattedMessage
    id="shared.clearText"
    defaultMessage="Clear"
    description=""
  />
);
