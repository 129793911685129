import React, { useState } from "react";
import { connect } from "react-redux";
import SidebarMainButton from "../sidebarShared/SidebarMainButton";
import SidebarExpander from "../sidebarShared/SidebarExpander";
import SvgThinArrowKnockoutIcon from "../svg/ThinArrowKnockoutIcon";
import MapViewListItem from "./MapViewListItem";
import MapViewNewViewButton from "./MapViewNewViewButton";
import SvgGlobeGridIcon from "../svg/GlobeGridIcon";
import { sort } from "../../utilities";

export function UnconnectedMapViewsList(props) {
  const {mapViewsArr, sidebarStatus, setSidebarStatus } = props
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    if (!sidebarStatus) {
      setSidebarStatus(true);
    }
    setExpanded(!expanded);
  }

  function displayMapviews() {
    const sortedViews = sort(mapViewsArr, "title", "az");
    const mapViews = sortedViews.map(view => {
      return (
        <MapViewListItem
          data-test="mapview-item"
          key={view.uid}
          id={view.uid}
          onClick={toggleExpanded}
        />
      );
    });
    return mapViews;
  }

  return (
    <React.Fragment>
      <SidebarMainButton
        leftIcon={<SvgGlobeGridIcon />}
        title="Map Views"
        rightIcon={<SvgThinArrowKnockoutIcon />}
        rightIconStyles={expanded ? "rotate-180-cw" : ""}
        onClick={toggleExpanded}
      />
      <SidebarExpander isExpanded={expanded}>
        <MapViewNewViewButton />
        {displayMapviews()}
      </SidebarExpander>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  const mapViewsArr = Object.values(state.mapViews);
  return {
    mapViewsArr
  };
};

export default connect(mapStateToProps)(UnconnectedMapViewsList);
