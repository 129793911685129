import React from "react";

const SvgLogoutIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M28.778 4.833C11.892 12.859.21 30.062.21 50c0 27.61 22.39 50 50 50s50-22.39 50-50c0-20.102-11.876-37.421-28.988-45.359l-2.629 17.3c9.322 6.033 15.495 16.514 15.495 28.447 0 18.713-15.157 33.877-33.87 33.877S16.341 69.101 16.341 50.388c0-11.773 6.008-22.131 15.119-28.2L28.778 4.833z" />
    <path d="M56.1 50.971H43.9a2.734 2.734 0 01-2.733-2.733V2.733A2.733 2.733 0 0143.9 0h12.2a2.734 2.734 0 012.733 2.733v45.505a2.732 2.732 0 01-2.733 2.733z" />
  </svg>
);

export default SvgLogoutIcon;
