import React from "react";
import SvgStarPointKnockoutIcon from "../svg/StarPointKnockoutIcon";
import { FormattedMessage } from "react-intl";

function Logo() {
  return (
    <div className="logo__main-container">
      <div className="logo__icon-container">
        <SvgStarPointKnockoutIcon className="text-xlarge" />
      </div>
      <span className="logo__title-container">
        <h1 className="logo__title">
          <FormattedMessage
            id="logo.siteNameText"
            defaultMessage="My Vacation Map"
            description=""
          />
        </h1>
      </span>
    </div>
  );
}
export default Logo;
