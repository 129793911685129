import {
  LOAD_MAPPOINTS,
  ADD_MAPPOINT,
  DELETE_MAPPOINT
} from "../actions/mapPointActions";

function mapPoints(state = {}, action) {
  switch (action.type) {
    case ADD_MAPPOINT:
      return {
        ...state,
        [action.uid]: {
          name: action.name,
          lat: action.lat,
          lng: action.lng,
          category: action.category,
          tags: action.tags,
          description: action.description,
          notes: action.notes,
          links: action.links,
          rating: action.rating,
          uid: action.uid
        }
      };
    case DELETE_MAPPOINT:
      let newMapPoints = { ...state };
      delete newMapPoints[action.meta.uid];
      return { ...newMapPoints };

    case LOAD_MAPPOINTS:
      return action.payload;
    default:
  }

  return state;
}

export default mapPoints;
