import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

// Import individual reducer files
import sessionToken from "./sessionTokenReducer";
import ui from "./uiReducer";
import authToken from "./authReducer";
import userInfo from "./userReducer";
import userLocation from "./userLocationReducer";
import mapViews from "./mapViewReducer";
import currentMapView from "./currentMapViewReducer";
import categories from "./categoryReducer";
import mapPoints from "./mapPointReducer";
import apiQueue from "./apiQueueReducer";
import multilingual from "./languagesReducer";
import editItemInformation from "./editItemReducer";
import { LOGOUT_USER } from "../actions/userActions";

const appReducer = combineReducers({
  ui,
  sessionToken,
  authToken,
  userInfo,
  userLocation,
  mapViews,
  currentMapView,
  categories,
  mapPoints,
  apiQueue,
  multilingual,
  editItemInformation
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    Object.keys(state).forEach(key => {
      storage.removeItem(`persist:${key}`);
    });
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
