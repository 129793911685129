export const SET_USER_LOCATION = "[user] set user current location";
export const USE_USER_LOCATION = "[user] use user current location";

export const setUserLocation = location => ({
  type: SET_USER_LOCATION,
  payload: location
});
export const useUserLocation = data => ({
  type: USE_USER_LOCATION,
  payload: data
});
