export const GET_SESSIONTOKEN = "[sessionToken] get sessionToken";
export const ADD_SESSIONTOKEN = "[sessionToken] add sessionToken";
export const CHECK_SESSIONTOKEN = "[sessionToken] check sessionToken";

export const checkSessionToken = () => ({
  type: CHECK_SESSIONTOKEN
});

export const getSessionToken = () => ({
  type: GET_SESSIONTOKEN
});

export const addSessionToken = data => ({
  type: ADD_SESSIONTOKEN,
  sessionToken: data.payload
});
