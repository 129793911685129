export const SET_LAST_CLICKED_LOCATION =
  "[edit point location] set last clicked location";
export const SET_EDIT_POINT_LOCATION =
  "[edit point location] set last clicked to be active";
export const SET_EDIT_POINT_ACTIVE =
  "[edit point location] set the edit point to be active or inactive";

export const setLastClickedLocation = location => {
  return {
    type: SET_LAST_CLICKED_LOCATION,
    payload: location
  };
};

export const setEditPointLocation = location => {
  return {
    type: SET_EDIT_POINT_LOCATION,
    payload: location
  };
};
export const setEditPointActive = value => {
  return {
    type: SET_EDIT_POINT_ACTIVE,
    payload: value
  };
};
