export const GET_MAPPOINTS = "[mapPoints] get mapPoints";
export const PROCESS_MAPPOINTS = "[mapPoints] process mapPoints";
export const LOAD_MAPPOINTS = "[mapPoints] load mapPoints";
export const UPDATE_MAPPOINT = "[mapPoints] update mapPoint";
export const PROCESS_UPDATED_MAPPOINT = "[mapPoints] process updated mapPoint";
export const NEW_MAPPOINT = "[mapPoints] new mapPoint";
export const PROCESS_MAPPOINT = "[mapPoints] process map point";
export const ADD_MAPPOINT = "[mapPoints] add mapPoint to store";
export const REMOVE_MAPPOINT = "[mapPoints] remove mapPoint";
export const PROCESS_REMOVED_MAPPOINT = "[mapPoints] process removed mapPoint";
export const DELETE_MAPPOINT = "[mapPoints] delete mapPoint from store";
export const MAP_POINT_ERROR = "[mapPoints] map point error";

export const getMapPoints = () => ({
  type: GET_MAPPOINTS
});
export const mapPointError = () => ({
  type: MAP_POINT_ERROR
});

export const processMapPoints = data => ({
  type: PROCESS_MAPPOINTS,
  payload: data
});

export const loadMapPoints = data => ({
  type: LOAD_MAPPOINTS,
  payload: data
});
export const processMapPoint = data => ({
  type: PROCESS_MAPPOINT,
  payload: data
});

export const updateMapPoint = (data, history) => ({
  type: UPDATE_MAPPOINT,
  payload: data,
  history: history
});

export const processUpdatedMapPoint = data => ({
  type: PROCESS_UPDATED_MAPPOINT,
  payload: data
});

export const newMapPoint = (data, history) => ({
  type: NEW_MAPPOINT,
  payload: data,
  history: history
});

export const removeMapPoint = data => ({
  type: REMOVE_MAPPOINT,
  payload: data
});

export const processRemovedMapPoint = data => ({
  type: PROCESS_REMOVED_MAPPOINT,
  payload: data
});
export const deleteMapPoint = data => ({
  type: DELETE_MAPPOINT,
  payload: data
});
