/*
 * This component sets up the Redux store with local storage. The local storage uses
 * the redux-persist package to save a copy of state to the local storage on the user's
 * device. Line 21 contains the state content that will not be copied to local storage.
 *
 * The mapPoints, mapViews, and categories are not saved to local state due to conflicts
 * when the local state is compared with the values coming from the server. This probably
 * can be fixed in the future to bring faster startup, but for now it's not a priority.
 *
 */

import { createStore, applyMiddleware, compose } from "redux";
import apiMiddleware from "./redux/middleware/apiMiddleware";
import { sessionTokenMdl } from "./redux/middleware/sessionTokenMiddleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./redux/reducers/index";
import { userMdl } from "./redux/middleware/userMiddleware";
import { authMdl } from "./redux/middleware/authMiddleware";
import { mapViewsMdl } from "./redux/middleware/mapViewMiddleware";
import { categoryMdl } from "./redux/middleware/categoryMiddleware";
import { createBrowserHistory } from "history";
import { mappointMdl } from "./redux/middleware/mappointMiddleware";
import { apiQueueMdl } from "./redux/middleware/apiQueueMiddleware";

export const history = createBrowserHistory();

const persistConfig = {
  key: "myVacationMapRoot",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(
      apiMiddleware,
      ...sessionTokenMdl,
      ...authMdl,
      ...userMdl,
      ...mapViewsMdl,
      ...categoryMdl,
      ...mappointMdl,
      ...apiQueueMdl
    )
  )
);

export const persistor = persistStore(store);

export default {
  store,
  persistor,
};

// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
