import { API_QUEUE_SUBMIT } from "../actions/apiActions";

const apiQueueSubmit = store => next => action => {
  next(action);
  if (action.type === API_QUEUE_SUBMIT) {
    const queue = Object.values(store.apiQueue);
    queue.map(request => {
      store.dispatch({ ...request });
    });
  }
};

export const apiQueueMdl = [apiQueueSubmit];
