import React from "react";

const SvgStarPointKnockoutIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M50-.2c-18.37 0-33.07 15.7-33.07 32.4C16.93 63.93 50 100 50 100s33.07-37.07 33.07-67.8C83.07 15.16 68.37-.2 50-.2zm0 45.26L34.25 56.5l6.01-18.51-15.74-11.44h19.46L50 8.04l6.01 18.51h19.46L59.73 37.99l6.01 18.51L50 45.06z" />
  </svg>
);

export default SvgStarPointKnockoutIcon;
