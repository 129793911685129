import React from "react";
import { connect } from "react-redux";
import SidebarSubButton from "../sidebarShared/SidebarSubButton";
import SvgPlusIcon from "../svg/PlusIcon";
import SvgDashKnockoutIcon from "../svg/DashKnockoutIcon";
import { setCurrentMapView } from "../../redux/actions/currentMapViewActions";
import { DELETE_MAPVIEW } from "../../redux/actions/mapViewsActions";
import { checkTokenStatus } from "../../utilities";

function MapViewListItem(props) {
  const { title, lat, lng, zoomLevel, uid } = props.mapView;

  function recenterMap() {
    const mapView = {
      lat,
      lng,
      zoomLevel
    };
    props.onClick();
    props.dispatch(setCurrentMapView(mapView));
  }

  function removeMapView() {
    props.onClick();
    checkTokenStatus({
      type: DELETE_MAPVIEW,
      payload: uid
    });
  }

  return (
    <React.Fragment>
      <SidebarSubButton
        // leftIcon={<SvgPlusIcon />}
        title={title}
        rightIcon={<SvgDashKnockoutIcon />}
        primaryOnClick={recenterMap}
        secondaryOnClick={removeMapView}
      />
    </React.Fragment>
  );
}
const mapStateToProps = (state, ownProps) => {
  const mapView = state.mapViews[ownProps.id];

  return {
    mapView
  };
};

export default connect(mapStateToProps)(MapViewListItem);
