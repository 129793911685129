import React from "react";
import L from "leaflet";
import { connect } from "react-redux";
import { Marker } from "react-leaflet";
import { Popup } from "react-leaflet";
import { iconObj } from "../../assets/MapPointIcons";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("../../assets/mapIcons/dotIcon.svg"),
  iconUrl: require("../../assets/mapIcons/dotIcon.svg"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export const dotIcon = new L.Icon({
  iconUrl: "/mapIcons/dotIcon.svg",
  iconRetinaUrl: "/mapIcons/dotIcon.svg",
  iconAnchor: [0, 0],
  popupAnchor: [0, 0],

  iconSize: [15, 15]
});

function UserLocationMarker(props) {
  const { lat, lng } = props.userPosition;
  const position = [lat, lng];

  return <Marker position={position} icon={dotIcon}></Marker>;
}

export default UserLocationMarker;
