import {
  CHECK_AUTHTOKEN,
  GET_AUTHTOKEN,
  PROCESS_AUTHTOKEN,
  REFRESH_AUTHTOKEN,
  addAuthToken,
  finishRefreshingAuthtoken,
  refreshingAuthtoken,
  refreshAuthtoken,
  PROCESS_NEW_AUTHTOKEN
} from "../actions/authActions";
import { apiRequest } from "../actions/apiActions";
import { showLoading, hideLoading } from "../actions/uiActions";

const getAuthTokenMdl = store => next => action => {
  next(action);
  if (action.type === GET_AUTHTOKEN) {
    const { username, password } = action.payload;
    let userLogin = new FormData();
    userLogin.append("grant_type", "password");
    userLogin.append("client_id", "cfa4575e-6bfa-438b-9d6a-054555785567");
    userLogin.append("client_secret", "lahoda614");
    userLogin.append("username", username);
    userLogin.append("password", password);

    const body = userLogin;

    const meta = {
      method: "POST",
      url: "oauth/token",
      body: true,
      onSuccess: PROCESS_NEW_AUTHTOKEN,
      onError: "",
      bodyType: null,
      description: "get token",
      history: action.history
    };
    store.dispatch(showLoading());
    store.dispatch(refreshingAuthtoken());
    store.dispatch(apiRequest(body, meta));
  }
};

const checkAuthToken = store => next => action => {
  next(action);
  if (action.type === CHECK_AUTHTOKEN) {
    const authToken = store.getState().authToken;
    if (authToken.tokenRefreshing === false && authToken.authToken === "") {
      // action.history.push("/login");
    } else {
      const tokenExpiration = new Date(authToken.tokenExpiration);
      const refreshTokenExpiration = new Date(authToken.refreshTokenExpiration);
      const currentDate = Date.now();
      // refresh token expires 1209600 sec after created, auth token expires 300 sec after creation, take 1209600, subtract 300, multiply by 1000 to convert to miliseconds, add to expiration and you get the date/time of when the refresh token expires
      const timeToTokenExpire = tokenExpiration - currentDate;

      const timeToRefreshTokenExpire = refreshTokenExpiration - currentDate;
      const tokenRefreshing = authToken.tokenRefreshing;

      if (
        timeToTokenExpire < 10000 &&
        timeToRefreshTokenExpire > 20000 &&
        !tokenRefreshing
      ) {
        if (action.request) {
          store.dispatch(refreshAuthtoken(action.request));
        } else {
          store.dispatch(refreshAuthtoken());
        }
      } else {
        // console.log("all good");
      }
    }
  }
};

const processAuthToken = store => next => action => {
  next(action);
  if (action.type === PROCESS_AUTHTOKEN) {
    const { access_token, expires_in, refresh_token } = action.payload;
    const currentDate = Date.now();
    const tokenExpiresIn = currentDate + expires_in * 1000;
    const refreshTokenExpiresIn = currentDate + 1209600000;

    const authInfo = {
      authToken: access_token,
      loggedIn: true,
      refreshToken: refresh_token,
      tokenExpiration: tokenExpiresIn,
      refreshTokenExpiration: refreshTokenExpiresIn,
      tokenRefreshing: false
    };

    store.dispatch(hideLoading());

    store.dispatch(addAuthToken(authInfo));
  }
};

const processNewAuthToken = store => next => action => {
  next(action);
  if (action.type === PROCESS_NEW_AUTHTOKEN) {
    const { access_token, expires_in, refresh_token } = action.payload;
    const currentDate = Date.now();
    const tokenExpiresIn = currentDate + expires_in * 1000;
    const refreshTokenExpiresIn = currentDate + 1209600000;
    const history = action.meta.history;
    const authInfo = {
      authToken: access_token,
      loggedIn: true,
      refreshToken: refresh_token,
      tokenExpiration: tokenExpiresIn,
      refreshTokenExpiration: refreshTokenExpiresIn,
      tokenRefreshing: false
    };
    store.dispatch(hideLoading());
    store.dispatch(addAuthToken(authInfo));
    store.dispatch(finishRefreshingAuthtoken());
    history.push("/app/map");
  }
};

const refreshUserAuthToken = store => next => action => {
  next(action);
  if (action.type === REFRESH_AUTHTOKEN) {
    const refreshToken = store.getState().authToken.refreshToken;
    let userLogin = new FormData();
    userLogin.append("grant_type", "refresh_token");
    userLogin.append("client_id", "adc1f45c-eeed-4dfb-8c47-77a8b3795f55");
    userLogin.append("client_secret", "lahoda614");
    userLogin.append("refresh_token", refreshToken);

    const meta = {
      method: "POST",
      url: "oauth/token",
      body: true,
      onSuccess: PROCESS_AUTHTOKEN,
      onError: ""
    };

    store.dispatch(refreshingAuthtoken());
    store.dispatch(apiRequest(userLogin, meta));
  }
};

export const authMdl = [
  getAuthTokenMdl,
  checkAuthToken,
  processAuthToken,
  refreshUserAuthToken,
  processNewAuthToken
];
