import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Popup } from "react-leaflet";
import { editText, viewText } from "../shared/SharedInternationalizations";

export function UnconnectedMapMarkerPopup(props) {
  const marker = props.marker;
  let desc = "";
  if (marker.description) {
    const description = marker.description;
    if (description.length < 100) {
      desc = description;
    } else {
      desc = description.substring(0, 100) + "...";
    }
  }

  return (
    <Popup data-test="marker-popup">
      <div
        className="marker-popup__marker-header-text text-large"
        data-test="marker-name"
      >
        {marker.name}
      </div>

      {desc !== "" ? (
        <div
          className="marker-popup__marker-description-text text-medium"
          data-test="marker-description"
        >
          {desc}
        </div>
      ) : (
        ""
      )}
      <div className="marker-popup__marker-buttons-container">
        <Link
          data-test="view-link"
          to={"/app/map/point/" + marker.uid + "/view/"}
          className="button__general-button link-button"
        >
          {viewText}
        </Link>
        <Link
          data-test="edit-link"
          to={"/app/map/point/" + marker.uid + "/edit/"}
          className="button__general-button link-button"
        >
          {editText}
        </Link>
      </div>
    </Popup>
  );
}

export default connect()(UnconnectedMapMarkerPopup);
