import React from "react";

const SvgGlobeGridIcon = props => (
  <svg
    id="globeGridIcon_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <style>
      {".globeGridIcon_svg__st0{stroke:currentColor;stroke-miterlimit:10}"}
    </style>
    <path
      className="globeGridIcon_svg__st0"
      d="M48.9 3.2c25.7 0 46.7 21 46.7 46.7s-21 46.7-46.7 46.7S2.2 75.7 2.2 49.9 23.1 3.2 48.9 3.2m0-3.1C21.4.1-.9 22.4-.9 49.9S21.4 99.8 49 99.8 98.8 77.5 98.8 50 76.4.1 48.9.1z"
    />
    <path
      className="globeGridIcon_svg__st0"
      d="M46.1 2h5.8v96h-5.8zM15.6 82.6L9.8 79c-4.3-20.1-4.1-37.1 0-58.5l5.8-3.3c-4.3 21.2-4.4 45 0 65.4zM33.7 94.7h-5.8C24 64.9 24 35.1 27.9 5.3h5.8c-4.7 29.8-4.7 59.6 0 89.4zM82.5 17.4l5.8 3.6c4.3 20.1 4.1 37.1 0 58.5l-5.8 3.3c4.2-21.2 4.4-45 0-65.4zM64.3 5.3h5.8c3.9 29.8 3.9 59.6 0 89.3h-5.8c4.8-29.7 4.7-59.5 0-89.3z"
    />
    <path
      transform="rotate(-90 48.95 49.922)"
      className="globeGridIcon_svg__st0"
      d="M46.1 1.9h5.8v96h-5.8z"
    />
    <path
      transform="rotate(-90 48.95 31.797)"
      className="globeGridIcon_svg__st0"
      d="M46.1-12.9h5.8v89.3h-5.8z"
    />
    <path
      className="globeGridIcon_svg__st0"
      d="M82.5 83.3l-5.3 5.8H19.3L14 83.3z"
    />
    <path
      transform="rotate(-90 48.95 68.047)"
      className="globeGridIcon_svg__st0"
      d="M46.1 23.4h5.8v89.3h-5.8z"
    />
    <path
      className="globeGridIcon_svg__st0"
      d="M78.7 10.8l5.1 5.8H15.6l3.2-5.8z"
    />
  </svg>
);

export default SvgGlobeGridIcon;
