import { INSERT_CATEGORY, LOAD_CATEGORIES } from "../actions/categoriesActions";

function categories(state = {}, action) {
  switch (action.type) {
    case INSERT_CATEGORY:
      return {
        ...state,
        [action.uid]: {
          name: action.name,
          uid: action.uid
        }
      };

    case LOAD_CATEGORIES:
      const incoming = action.payload

      return {...incoming};

    default:
      return state;
  }
}

export default categories;
