import React from "react";
import { connect } from "react-redux";
import { setCurrentMapView } from "../../redux/actions/currentMapViewActions";
import { Map, TileLayer, ZoomControl } from "react-leaflet";
import MarkerWrapper from "./MarkerWrapper";
import { setLastClickedLocation } from "../../redux/actions/editItemActions";
import EditMarkerWrapper from "./EditMarkerWrapper";
import UserLocationMarker from "./UserLocationMarker";

export function UnconnectedMapDisplay(props) {
  const { mapPoints, editItem, useUserLocation } = props;
  const { zoomLevel, lat, lng } = props.currentMapView;
  const { lat:userLat, lng:userLng } = props.userLocation;
  const position = useUserLocation? [userLat, userLng] : [lat, lng];

  function onViewportChanged(viewport) {
    const [lat, lng] = viewport.center;
    const zoom = viewport.zoom;
    const view = { lat, lng, zoomLevel: zoom };
    props.dispatch(setCurrentMapView(view));
  }

  function displayEditMarker() {
    if (editItem.point.editPoint && !editItem.point.editPointId) {
      return (
        <EditMarkerWrapper
          data-test="edit-marker"
          editPosition={{
            lat: editItem.point.editLat,
            lng: editItem.point.editLng
          }}
        />
      );
    }
  }

  function displayUserLocationMarker() {
  if (userLat && userLng) {
    return (
      <UserLocationMarker
      data-test="user-marker"
      userPosition={{
        lat: userLat,
        lng: userLng
      }}
      />
      );
    } else {
      return null
    }
  }

  function displayMapMarkers() {
    const displayPoints = mapPoints.map((point, index) => {
      if (
        editItem.point.editPoint &&
        editItem.point.editPointId &&
        editItem.point.editPointId === point
      ) {
        return (
          <EditMarkerWrapper
            data-test="edit-marker"
            pointId={point}
            key={point}
            editPosition={{
              lat: editItem.point.editLat,
              lng: editItem.point.editLng
            }}
          />
        );
      } else {
        return (
          <MarkerWrapper pointId={point} key={point} data-test="map-marker" />
        );
      }
    });
    return displayPoints;
  }

  function setClickLocation(event) {
    const click = {
      lat: event.latlng.lat,
      lng: event.latlng.lng
    };
    props.dispatch(setLastClickedLocation(click));
  }

  return (
    <Map
      center={position}
      zoom={zoomLevel}
      onViewportChanged={onViewportChanged}
      zoomControl={false}
      onClick={setClickLocation}
      data-test="map"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        data-test="tile-layer"
      />
      <ZoomControl position="topright" data-test="zoom-control" />
      {displayUserLocationMarker()}
      {displayMapMarkers()}
      {displayEditMarker()}
    </Map>
  );
}

const mapStateToProps = state => {
  return {
    mapPoints: Object.keys(state.mapPoints),
    currentMapView: state.currentMapView,
    editItem: state.editItemInformation,
    userLocation: state.userLocation.location,
    useUserLocation: state.userLocation.useUserLocation
  };
};

export default connect(mapStateToProps)(UnconnectedMapDisplay);
