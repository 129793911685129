import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import XKnockoutIcon from "../svg/XKnockoutIcon";
import { setCurrentMapView } from "../../redux/actions/currentMapViewActions";
import {
  categoryText,
  descriptionText,
  notesText,
  ratingText,
  linksText,
  editText,
  deleteText,
  challengeText,
  cancelText
} from "../shared/SharedInternationalizations";
import RatingStars from "../svg/RatingStars";
import SvgOpenExternalIcon from "../svg/OpenExternalIcon";
import SidebarMainLinkExternal from "../sidebarShared/SidebarMainLinkExternal";
import SvgLinkIcon from "../svg/LinkIcon";
import { REMOVE_MAPPOINT } from "../../redux/actions/mapPointActions";
import Overlay from "../shared/Overlay";
import { checkTokenStatus } from "../../utilities";

export function UnconnectedMapPointView(props) {
  const { mapPoint, categories, sidebarStatus, setSidebarStatus } = props;
  const {
    name,
    lat,
    lng,
    category,
    tags,
    description,
    notes,
    links,
    rating,
    uid
  } = mapPoint;
  const zoomLevel = props.currentMapView.zoomLevel;
  const mapView = {
    lat,
    lng,
    zoomLevel
  };

  
  const [viewDeleteChallenge, setViewDeleteChallenge] = useState(false);

  useEffect(() => {
    props.dispatch(setCurrentMapView(mapView));
    if (!sidebarStatus) {
      setSidebarStatus(true);
    }
  }, []); // eslint-disable-line

  const linksDisplay = links.map((link, index) => {
    return (
      <SidebarMainLinkExternal
        data-test="link-item"
        key={index}
        link={link.uri}
        title={link.title}
        leftIcon={<SvgLinkIcon />}
        rightIcon={<SvgOpenExternalIcon />}
      />
    );
  });

  function showDeleteChallege() {
    setViewDeleteChallenge(true);
  }

  function hideDeleteChallenge() {
    setViewDeleteChallenge(false);
  }

  function deleteChallenge() {
    if (viewDeleteChallenge) {
      return (
        <Overlay
          rootUrl={`/app/map/point/${uid}/view/`}
          canClose={false}
          data-test="user-archive-challenge-overlay"
          useFunction={true}
          onClick={hideDeleteChallenge}
        >
          <div className="content-container">
            <h1 className="no-margin">
              {deleteText} "{name}"?
            </h1>
            {challengeText}
            <hr />
            <div className="flex-end width-100">
              <button
                className="button__general-button"
                onClick={hideDeleteChallenge}
              >
                {cancelText}
              </button>
              <button
                className="button__general-button button__alert"
                onClick={removePoint}
              >
                {deleteText}
              </button>
            </div>
          </div>
        </Overlay>
      );
    } else {
      return "";
    }
  }

  function removePoint() {
    props.history.push("/app/map/");
    checkTokenStatus({
      type: REMOVE_MAPPOINT,
      payload: uid
    });
  }

  const categoryDisplay = categories[category].name;
  return (
    <React.Fragment>
      {deleteChallenge()}
      <div className="sidebar__close-button-container">
        <Link to="/app/map/">
          <XKnockoutIcon className="button__close-button-icon" />
        </Link>
      </div>

      <div className="point-view__main-container">
        <h1 className="text-center" data-test="name">
          {name}
        </h1>

        <div className="point-view__section-container">
          <div
            className="point-view__section-container--column"
            data-test="category"
          >
            <div className="point-view__section-heading">{categoryText}</div>
            {categoryDisplay}
          </div>

          <div className="point-view__section-container--column">
            <div className="point-view__section-heading">{ratingText}</div>
            <RatingStars rating={rating} />
          </div>
        </div>
        <div className="point-view__section-container" data-test="description">
          <div className="point-view__section-heading">{descriptionText}</div>
          {description}
        </div>
        <div className="point-view__section-container" data-test="notes">
          <div className="point-view__section-heading">{notesText}</div>
          {notes}
        </div>
        <div className="point-view__section-container" data-test="links">
          <div className="point-view__section-heading">{linksText}</div>
          {linksDisplay}
        </div>
        <div className="point-view__section-container ">
          <Link
            data-test="edit-link"
            to={"/app/map/point/" + uid + "/edit/"}
            className="button__general-button link-button"
          >
            {editText}
          </Link>

          <button
            data-test="delete-link"
            className="button__general-button"
            onClick={showDeleteChallege}
          >
            {" "}
            {deleteText}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const mapPoint = state.mapPoints[id];

  return {
    mapPoint,
    categories: state.categories,
    currentMapView: state.currentMapView
  };
};

export default withRouter(connect(mapStateToProps)(UnconnectedMapPointView));
