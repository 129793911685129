import {
  SUCCESS_REGISTER_USER,
  PROCESS_CURRENT_USER_INFO,
  PROCESS_CURRENT_USER_ID
} from "../actions/userActions";

function userInfo(state = { name: "", uid: "", lastLocation: {} }, action) {
  switch (action.type) {
    case "ADD_USERINFO":
      return {
        name: action.name,
        uid: action.uid
      };
    case SUCCESS_REGISTER_USER:
      return {
        loginStatus: ""
      };
    case PROCESS_CURRENT_USER_INFO:
      return {
        ...state,
        currentInfo: action.payload
      };
    case PROCESS_CURRENT_USER_ID:
      const jsonApi = action.payload;
      const id = jsonApi.meta.links.me.meta.id;
      return {
        ...state,
        uid: id
      };
    default:
  }

  return state;
}

export default userInfo;
