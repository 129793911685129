export const UPDATE_LANGUAGE = "[language] update language";
export const ADD_LANGUAGES = "[language] add languages";

export function updateLanguage(language) {
  return {
    type: UPDATE_LANGUAGE,
    language: language
  };
}

export function addLanguages(languages) {
  return {
    type: ADD_LANGUAGES,
    languages: languages
  };
}
