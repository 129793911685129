import React from "react";

const SvgDashKnockoutIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M50.21 0C22.6 0 .21 22.39.21 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50zm35.23 55.71H14.99V44.3h70.45v11.41z" />
  </svg>
);

export default SvgDashKnockoutIcon;
