import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import XKnockoutIcon from "../svg/XKnockoutIcon";
import AddEditMapPointForm from "./AddEditMapPointForm";
import { editText } from "../shared/SharedInternationalizations";
import { UPDATE_MAPPOINT } from "../../redux/actions/mapPointActions";
import { checkTokenStatus } from "../../utilities";

export function UnconnectedMapPointEdit(props) {
  const {mapPoint, sidebarStatus, setSidebarStatus } = props
  const { name, uid } =  mapPoint;

  useEffect(() => {
    if (!sidebarStatus) {
      setSidebarStatus(true);
    }
  }, []); // eslint-disable-line

  function editSubmit(values) {
    checkTokenStatus({
      type: UPDATE_MAPPOINT,
      payload: values,
      history: props.history
    });
  }
  const linkAddress = "/app/map/point/" + uid + "/view/";

  return (
    <React.Fragment>
      <div className="sidebar__close-button-container">
        <Link to={linkAddress} data-test="close-link">
          <XKnockoutIcon className="button__close-button-icon" />
        </Link>
      </div>
      <h1 className="text-center">
        {editText}: "{name}"
      </h1>
      <AddEditMapPointForm
        id={uid}
        onSubmit={editSubmit}
        link={linkAddress}
        data-test="add-edit-form"
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const mapPoint = state.mapPoints[id];

  return {
    mapPoint
  };
};

export default withRouter(connect(mapStateToProps)(UnconnectedMapPointEdit));
