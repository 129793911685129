import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import Map from "./components/map/Map";
import { history } from "./store";
import Login from "./components/login/Login";
import SplashScreen from "./components/SplashScreen";
import { checkSessionToken } from "./redux/actions/sessionTokenActions";
import { checkAuthToken } from "./redux/actions/authActions";
import { apiQueueUpdate } from "./redux/actions/apiActions";
import Account from "./components/Account/Account";
import { IntlProvider } from "react-intl";
import messages_fr from "./translations/locales/fr.json";
import messages_en from "./translations/locales/en.json";
import { addLanguages } from "./redux/actions/languagesActions";
import { HelmetProvider } from "react-helmet-async";
import PrivateRoute from "./components/login/PrivateRoute";
import LoaderBar from "./components/ui/LoaderBar";
import RedirectToLogin from "./components/shared/RedirectToLogin";

function App(props) {
  const sessionToken = props.sessionToken;
  const authToken = props.authToken;
  const apiQueue = props.apiQueue;
  useEffect(() => {
    props.dispatch(checkSessionToken());
    const languages = [
      { name: "English", id: "en" },
      { name: "Français", id: "fr" },
    ];

    // This loads the list of languages into the Redux store
    props.dispatch(addLanguages(languages));
  }, []); // eslint-disable-line
  useEffect(() => {
    if (sessionToken === "" || sessionToken === null) {
      props.dispatch(checkAuthToken(history));
      // } else if (
      //   authToken.authToken === "" &&
      //   history.location !== "/login" &&
      //   history.location !== "/register"
      // ) {
      //   history.push("/login");
    }
  }, [sessionToken]); // eslint-disable-line
  useEffect(() => {
    if (
      !authToken.tokenRefreshing &&
      authToken.loggedIn &&
      apiQueue.length > 0
    ) {
      const queue = [...apiQueue];
      const request = queue.shift();
      props.dispatch({ ...request });
      props.dispatch(apiQueueUpdate(queue));
    }
  }, [apiQueue, authToken]); // eslint-disable-line
  const currentLanguage = props.selectedLanguage;

  const messages = {
    fr: messages_fr,
    en: messages_en,
  };

  return (
    <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
      <HelmetProvider>
        <LoaderBar />
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={SplashScreen} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Login} />
            <PrivateRoute
              path="/account"
              component={Account}
              auth={props.authToken.loggedIn}
            />
            <PrivateRoute
              path="/logout"
              component={RedirectToLogin}
              auth={props.authToken.loggedIn}
            />
            <PrivateRoute
              path="/app/map"
              component={Map}
              auth={props.authToken.loggedIn}
            />

            <Route component={() => <div>404 Not found </div>} />
          </Switch>
        </Router>
      </HelmetProvider>
    </IntlProvider>
  );
}
// </HelmetProvider>
const mapStateToProps = state => {
  return {
    sessionToken: state.sessionToken,
    authToken: state.authToken,
    selectedLanguage: state.multilingual.selectedLanguage,
    apiQueue: state.apiQueue,
  };
};

export default connect(mapStateToProps)(App);
