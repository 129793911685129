import React from "react";
import SvgLogoutIcon from "../svg/LogoutIcon";
import SidebarSubLink from "../sidebarShared/SidebarSubLink";
import { logOutText } from "../shared/SharedInternationalizations";

function LogoutButton(props) {
  return (
    <React.Fragment>
      <SidebarSubLink
        leftIcon={<SvgLogoutIcon />}
        title={logOutText}
        link="/logout"
      />
    </React.Fragment>
  );
}

export default LogoutButton;
