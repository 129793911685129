// UI oriented reducers for Redux
import {
  SHOW_LOADING,
  HIDE_LOADING,
  REGISTER_MSG,
  CLEAR_LOGIN_MSG,
  ADD_UI_MSG,
  CLEAR_UI_MSG
} from "../actions/uiActions";

const initUi = {
  loading: 0,
  loginMessage: "",
  uiMessags: {}
};

function ui(state = initUi, action) {
  switch (action.type) {
    case SHOW_LOADING:
      // Add 1 to the value in the loading state each time this reducer is called
      const showLoadingNum = state.loading + 1;
      return { ...state, loading: showLoadingNum };

    case HIDE_LOADING:
      // Subtract 1 to the value in the loading state each time this reducer is called
      const hideLoadingNum = state.loading;
      if (hideLoadingNum === 0) {
        return { ...state, loading: 0 };
      } else {
        return { ...state, loading: hideLoadingNum - 1 };
      }

    case ADD_UI_MSG:
      const id = (
        Date.now().toString(36) +
        Math.random()
          .toString(36)
          .substr(2, 5)
      ).toUpperCase();
      const message = action.payload;
      message.id = id;

      return {
        ...state,
        uiMessages: {
          ...state.uiMessages,
          [id]: message
        }
      };

    case CLEAR_UI_MSG:
      const updatedMessages = state.uiMessages;
      delete updatedMessages[action.payload];
      return {
        ...state,
        uiMessages: {
          ...updatedMessages
        }
      };

    case REGISTER_MSG:
      return {
        ...state,
        loginMessage: action.payload
      };

    case CLEAR_LOGIN_MSG:
      return {
        ...state,
        loginMessage: ""
      };

    default:
      return state;
  }
}
export default ui;
