import { SET_CURRENTMAPVIEW } from "../actions/currentMapViewActions";

function currentMapView(
  state = {
    zoomLevel: 13,
    lat: 48.85887052169242,
    lng: 2.3288440704345708
  },
  action
) {
  switch (action.type) {
    case SET_CURRENTMAPVIEW:
      const updatedMapView = action.payload;
      return {
        lat: updatedMapView.lat,
        lng: updatedMapView.lng,
        zoomLevel: updatedMapView.zoomLevel
      };

    default:
  }

  return state;
}

export default currentMapView;
