import {
  GET_MAPPOINTS,
  PROCESS_MAPPOINTS,
  LOAD_MAPPOINTS,
  UPDATE_MAPPOINT,
  PROCESS_UPDATED_MAPPOINT,
  NEW_MAPPOINT,
  PROCESS_NEW_MAPPOINT,
  ADD_MAPPOINT,
  REMOVE_MAPPOINT,
  PROCESS_REMOVED_MAPPOINT,
  DELETE_MAPPOINT,
  MAP_POINT_ERROR,
  loadMapPoints,
  PROCESS_MAPPOINT,
} from "../actions/mapPointActions";
import { apiRequest } from "../actions/apiActions";
import { showLoading, hideLoading } from "../actions/uiActions";

const getMapPoints = store => next => action => {
  next(action);

  if (action.type === GET_MAPPOINTS) {
    const token = store.getState().authToken.authToken;
    const sessionToken = store.getState().sessionToken;
    const body = null;
    const meta = {
      method: "GET",
      url: "entity/view/user_map_points?_format=json",
      body: false,
      onSuccess: PROCESS_MAPPOINTS,
      onError: MAP_POINT_ERROR,
      endPointType: "rest",
      authToken: token,
      sessionToken: sessionToken,
    };
    store.dispatch(showLoading());
    store.dispatch(apiRequest(body, meta));
  }
};

const processMapPoints = store => next => action => {
  next(action);
  if (action.type === PROCESS_MAPPOINTS) {
    let pointsObj = { ...store.getState().mapPoints };
    const apiData = action.payload;

    apiData.map(mapPoint => {
      const pointUuid = mapPoint.uuid[0].value;
      const revisionDate = mapPoint.revision_timestamp[0].value;
      let description = "";
      if (mapPoint.field_description && mapPoint.field_description[0]) {
        description = mapPoint.field_description[0].value;
      }
      let notes = "";
      if (mapPoint.field_notes && mapPoint.field_notes[0]) {
        notes = mapPoint.field_notes[0].value;
      }
      let rating = 0;
      if (mapPoint.field_rating[0]) {
        rating = mapPoint.field_rating[0].value;
      }
      if (pointsObj[pointUuid]) {
        const prevDate = pointsObj[pointUuid].revision;
        if (prevDate < revisionDate) {
          pointsObj[pointUuid] = {
            name: mapPoint.title[0].value,
            lat: mapPoint.field_lat_lng[0].lat,
            lng: mapPoint.field_lat_lng[0].lng,
            category: mapPoint.field_category[0].target_uuid,
            tags: mapPoint.field_tags,
            description: description,
            notes: notes,
            links: mapPoint.field_links,
            rating: rating,
            uid: mapPoint.uuid[0].value,
            revision: mapPoint.revision_timestamp[0].value,
          };
        }
      } else {
        pointsObj[pointUuid] = {
          name: mapPoint.title[0].value,
          lat: mapPoint.field_lat_lng[0].lat,
          lng: mapPoint.field_lat_lng[0].lng,
          category: mapPoint.field_category[0].target_uuid,
          tags: mapPoint.field_tags,
          description: description,
          notes: notes,
          links: mapPoint.field_links,
          rating: rating,
          uid: mapPoint.uuid[0].value,
          revision: mapPoint.revision_timestamp[0].value,
        };
      }
    });
    store.dispatch(hideLoading());
    store.dispatch(loadMapPoints(pointsObj));
  }
};
const processMapPoint = store => next => action => {
  next(action);
  if (action.type === PROCESS_MAPPOINT) {
    let pointsObj = { ...store.getState().mapPoints };
    const mapPoint = action.payload.data;

    const pointUuid = mapPoint.id;
    const revisionDate = new Date(mapPoint.attributes.revision_timestamp);
    let description = "";
    if (mapPoint.attributes.field_description) {
      description = mapPoint.attributes.field_description;
    }
    let notes = "";
    if (mapPoint.attributes.field_notes) {
      notes = mapPoint.attributes.field_notes;
    }
    if (pointsObj[pointUuid]) {
      const prevDate = new Date(pointsObj[pointUuid].revision);
      if (prevDate < revisionDate) {
        pointsObj[pointUuid] = {
          name: mapPoint.attributes.title,
          lat: mapPoint.attributes.field_lat_lng.lat,
          lng: mapPoint.attributes.field_lat_lng.lng,
          category: mapPoint.relationships.field_category.data.id,
          tags: mapPoint.attributes.field_tags,
          description: description,
          notes: notes,
          links: mapPoint.attributes.field_links,
          rating: mapPoint.attributes.field_rating,
          uid: mapPoint.id,
          revision: mapPoint.attributes.revision_timestamp,
        };
      }
    } else {
      pointsObj[pointUuid] = {
        name: mapPoint.attributes.title,
        lat: mapPoint.attributes.field_lat_lng.lat,
        lng: mapPoint.attributes.field_lat_lng.lng,
        category: mapPoint.relationships.field_category.data.id,
        tags: mapPoint.attributes.field_tags,
        description: description,
        notes: notes,
        links: mapPoint.attributes.field_links,
        rating: mapPoint.attributes.field_rating,
        uid: mapPoint.id,
        revision: mapPoint.attributes.revision_timestamp,
      };
    }
    store.dispatch(loadMapPoints(pointsObj));
    action.meta.history.push("/app/map/");
    // action.meta.history.push("/app/map/point/" + pointUuid + "/view/");
    store.dispatch(hideLoading());
  }
};

const updateMappoint = store => next => action => {
  next(action);
  if (action.type === UPDATE_MAPPOINT) {
    const token = store.getState().authToken.authToken;
    const point = action.payload;
    const validatedLinks = [];
    point.links.map(link => {
      const linkStartValidator = RegExp("^https?://(.*)", "i");
      if (linkStartValidator.test(link.uri)) {
        validatedLinks.push(link);
      } else {
        link.uri = "http://" + link.uri;
        validatedLinks.push(link);
      }
    });

    const body = {
      data: {
        type: "node--map_point",
        id: point.uid,
        attributes: {
          title: point.name,
          field_description: point.description,
          field_lat_lng: {
            lat: point.lat,
            lng: point.lng,
          },
          field_links: validatedLinks,
          field_notes: point.notes,
          field_rating: point.rating,
        },
        relationships: {
          field_category: {
            data: {
              type: "taxonomy_term--categories",
              id: point.category,
            },
          },
        },
      },
    };

    const meta = {
      method: "PATCH",
      url: "jsonapi/node/map_point/" + action.payload.uid,
      body: true,
      onSuccess: PROCESS_MAPPOINT,
      onError: MAP_POINT_ERROR,
      endPointType: "json",
      authToken: token,
      bodyType: "json",
      history: action.history,
    };

    store.dispatch(showLoading());
    store.dispatch(apiRequest(body, meta));
  }
};
const removeMappoint = store => next => action => {
  next(action);
  if (action.type === REMOVE_MAPPOINT) {
    const token = store.getState().authToken.authToken;
    const body = "";
    const meta = {
      method: "DELETE",
      url: "jsonapi/node/map_point/" + action.payload,
      onSuccess: DELETE_MAPPOINT,
      onError: MAP_POINT_ERROR,
      body: false,
      endPointType: "json",
      authToken: token,
      uid: action.payload,
    };
    store.dispatch(showLoading());
    store.dispatch(apiRequest(body, meta));
  }
};

const newMappoint = store => next => action => {
  next(action);
  if (action.type === NEW_MAPPOINT) {
    const token = store.getState().authToken.authToken;
    const point = action.payload;
    const validatedLinks = [];
    if (point.links && point.links.length > 0) {
      point.links.map(link => {
        const linkStartValidator = RegExp("^https?://(.*)", "i");
        if (linkStartValidator.test(link.uri)) {
          validatedLinks.push(link);
        } else {
          link.uri = "http://" + link.uri;
          validatedLinks.push(link);
        }
      });
    }
    const categoryArr = Object.values(store.getState().categories);
    const singleCategoryArr = categoryArr.filter(cat => cat.name === "Other");
    const otherCategory = singleCategoryArr[0];
    let category = otherCategory.uid;
    if (point.category) {
      category = point.category;
    }

    const body = {
      data: {
        type: "node--map_point",
        attributes: {
          title: point.name,
          field_description: point.description,
          field_lat_lng: {
            lat: point.lat,
            lng: point.lng,
          },
          field_links: validatedLinks,
          field_notes: point.notes,
          field_rating: point.rating,
        },
        relationships: {
          field_category: {
            data: {
              type: "taxonomy_term--categories",
              id: category,
            },
          },
        },
      },
    };

    const meta = {
      method: "POST",
      url: "jsonapi/node/map_point/",
      body: true,
      onSuccess: PROCESS_MAPPOINT,
      onError: MAP_POINT_ERROR,
      endPointType: "json",
      authToken: token,
      bodyType: "json",
      history: action.history,
    };

    store.dispatch(showLoading());
    store.dispatch(apiRequest(body, meta));
  }
};

const deleteMapPoint = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === DELETE_MAPPOINT) {
    dispatch(hideLoading());
  }
};
const mapPointError = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === MAP_POINT_ERROR) {
    console.error(action);

    dispatch(hideLoading());
  }
};
export const mappointMdl = [
  getMapPoints,
  updateMappoint,
  removeMappoint,
  newMappoint,
  processMapPoints,
  deleteMapPoint,
  mapPointError,
  processMapPoint,
];
