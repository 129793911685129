import React from "react";

const SvgTargetIcon = props => (
  <svg
    viewBox="0 0 21.2 21.2"
    {...props}
    stroke="currentColor"
    fill="none"
  >
   <circle cx="10.6" cy="10.6" r="6.8"/>
  <path d="M10.6 7V0M10.6 21.2v-7M7 10.6H0M21.2 10.6h-7"/>
  </svg>
);

export default SvgTargetIcon;
