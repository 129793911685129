import React from "react";
import { connect } from "react-redux";
import Nprogress from "nprogress";

/**
 * The component containing the loader bar
 */
export function LoaderBar(props) {
  /**
   * Function to trigger the start and end of the loader bar
   * @description - This checks the Redux store value for the loading value. If the value is over 0 it will show the loader bar, if it drops to 0 it will finish the loader.This allows for multiple concurrent events to occur with the loader bar continuing to advance and only completing when the last event is finished
   */
  function handleLoader() {
    if (props.loading > 0) {
      Nprogress.start();
    } else {
      Nprogress.done();
    }
  }

  Nprogress.configure({ parent: "#loaderBar" });
  handleLoader();
  return (
    <React.Fragment>
      <div id="loaderBar" />
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.ui.loading
  };
};

export default connect(mapStateToProps)(LoaderBar);
