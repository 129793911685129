export const CHECK_AUTHTOKEN = "[authToken] check authToken";
export const ADD_AUTHTOKEN = "[authToken] add authToken";
export const GET_AUTHTOKEN = "[authToken] get authToken";
export const PROCESS_AUTHTOKEN = "[authToken] process authToken";
export const PROCESS_NEW_AUTHTOKEN = "[authToken] process new authToken";
export const REFRESH_AUTHTOKEN = "[authToken] refresh authToken";
export const REFRESHING_AUTHTOKEN = "[authToken] refreshing authToken";
export const FINISH_REFRESHING_AUTHTOKEN =
  "[authToken] finished refreshing authToken";

export const checkAuthToken = (history, request) => ({
  type: CHECK_AUTHTOKEN,
  request,
  history
});

export const getAuthToken = (login, history) => ({
  type: GET_AUTHTOKEN,
  payload: login,
  history
});

export const processAuthToken = () => ({
  type: PROCESS_AUTHTOKEN
});
export const processNewAuthToken = () => ({
  type: PROCESS_NEW_AUTHTOKEN
});

export const addAuthToken = token => ({
  type: ADD_AUTHTOKEN,
  payload: token
});

export const refreshingAuthtoken = () => ({
  type: REFRESHING_AUTHTOKEN
});

export const finishRefreshingAuthtoken = () => ({
  type: FINISH_REFRESHING_AUTHTOKEN
});
export const refreshAuthtoken = request => ({
  type: REFRESH_AUTHTOKEN,
  request
});
