import React from "react";

const SvgThickArrowKnockoutIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M50.21 0C22.6 0 .21 22.39.21 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50zm0 90.49L22.64 56.83h13.63V13.67h27.89v43.16h13.62L50.21 90.49z" />
  </svg>
);

export default SvgThickArrowKnockoutIcon;
