import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import XKnockoutIcon from "../svg/XKnockoutIcon";
import AddEditMapPointForm from "./AddEditMapPointForm";
import { addMapPointText } from "../shared/SharedInternationalizations";
import { NEW_MAPPOINT } from "../../redux/actions/mapPointActions";
import { checkTokenStatus } from "../../utilities";

export function UnconnectedAddMapPoint(props) {
  const {sidebarStatus, setSidebarStatus } = props

  useEffect(() => {
    if (!sidebarStatus) {
      setSidebarStatus(true);
    }
  }, []); // eslint-disable-line

  function addSubmit(values) {
    checkTokenStatus({
      type: NEW_MAPPOINT,
      payload: values,
      history: props.history
    });
  }

  const linkAddress = "/app/map/";

  return (
    <React.Fragment>
      <div className="sidebar__close-button-container">
        <Link to={linkAddress} data-test="close-link">
          <XKnockoutIcon className="button__close-button-icon" />
        </Link>
      </div>
      <h1 className="text-center">{addMapPointText}</h1>
      <AddEditMapPointForm
        onSubmit={addSubmit}
        link={linkAddress}
        data-test="add-edit-form"
      />
    </React.Fragment>
  );
}

export default withRouter(connect()(UnconnectedAddMapPoint));
