import React from "react";
import { connect } from "react-redux";
import SvgUserKnockoutIcon from "../svg/UserKnockoutIcon";
import SidebarSubLink from "../sidebarShared/SidebarSubLink";
import { accountText } from "../shared/SharedInternationalizations";

function UserAccountButton(props) {
  return (
    <React.Fragment>
      <SidebarSubLink
        leftIcon={<SvgUserKnockoutIcon />}
        title={accountText}
        link="/account"
      />
    </React.Fragment>
  );
}

export default connect()(UserAccountButton);
