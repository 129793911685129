import React from "react";

const SvgPlusKnockoutIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M50.21 0C22.6 0 .21 22.39.21 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50zm35.23 55.71H55.92v29.51H44.51V55.71H14.99V44.3h29.52V14.77h11.41V44.3h29.52v11.41z" />
  </svg>
);

export default SvgPlusKnockoutIcon;
