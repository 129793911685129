import React from "react";
import SvgStarSolid from "./StarSolid";
import SvgStarStroke from "./StarStroke";

function RatingStars(props) {
  switch (props.rating) {
    case 1:
      return (
        <div className="rating-star-container">
          <SvgStarSolid />
          <SvgStarStroke />
          <SvgStarStroke />
          <SvgStarStroke />
          <SvgStarStroke />
        </div>
      );

    case 2:
      return (
        <div className="rating-star-container">
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarStroke />
          <SvgStarStroke />
          <SvgStarStroke />
        </div>
      );

    case 3:
      return (
        <div className="rating-star-container">
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarStroke />
          <SvgStarStroke />
        </div>
      );

    case 4:
      return (
        <div className="rating-star-container">
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarStroke />
        </div>
      );

    case 5:
      return (
        <div className="rating-star-container">
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarSolid />
          <SvgStarSolid />
        </div>
      );

    default:
      return (
        <div className="rating-star-container">
          <SvgStarStroke />
          <SvgStarStroke />
          <SvgStarStroke />

          <SvgStarStroke />
          <SvgStarStroke />
        </div>
      );
  }
}

export default RatingStars;
