import React, { useRef, useState, useEffect } from "react";
import { getAuthToken, checkAuthToken } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import Logo from "../shared/Logo";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet-async";
import {
  passwordText,
  emailText,
  cancelText,
  firstNameText,
  lastNameText,
} from "../shared/SharedInternationalizations";
import { registerUser } from "../../redux/actions/userActions";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { clearLoginMsg } from "../../redux/actions/uiActions";
import { history } from "../../store";
import { checkSessionToken } from "../../redux/actions/sessionTokenActions";

function Login(props) {
  const sessionToken = props.sessionToken;
  console.log(sessionToken);
  useEffect(() => {
    if (sessionToken === "" || sessionToken === null) {
      props.dispatch(checkSessionToken());
      // } else if (
      //   authToken.authToken === "" &&
      //   history.location !== "/login" &&
      //   history.location !== "/register"
      // ) {
      //   history.push("/login");
    }
  }, [sessionToken]); // eslint-disable-line
  const [registrationMessage, setRegistrationMessage] = useState("");

  const today = new Date();
  const year = today.getFullYear();
  let formContent;

  const emailRef = useRef("");
  const passwordRef = useRef("");
  const regFirstNameRef = useRef("");
  const regLastNameRef = useRef("");
  const regEmailRef = useRef("");
  const regPasswordRef = useRef("");

  const loginMsg = props.loginMsg;

  useEffect(() => {
    // setRegistrationMessage(loginMsg);
    setTimeout(() => {
      // props.dispatch(clearLoginMsg());
      setRegistrationMessage("");
    }, 7000);
  }, [loginMsg]);

  function handleRegSubmit(event) {
    const userInfo = {
      username: regEmailRef.current.value,
      password: regPasswordRef.current.value,
      firstName: regFirstNameRef.current.value,
      lastName: regLastNameRef.current.value,
    };
    props.dispatch(registerUser(userInfo, props.history));
    event.preventDefault();
  }

  function handleSubmit(event) {
    const login = {
      username: emailRef.current.value,
      password: passwordRef.current.value,
    };
    props.dispatch(getAuthToken(login, props.history));
    event.preventDefault();
  }
  return (
    <main className="login__container" data-test="login">
      <div className="login__content-container">
        <Logo data-test="logo" />
        <span className="login__login-message">{registrationMessage}</span>
        <Switch>
          <Route path="/login">
            <LoginForm
              onSubmit={handleSubmit}
              emailRef={emailRef}
              passwordRef={passwordRef}
            />
          </Route>
          <Route path="/register">
            <RegisterForm
              regFirstNameRef={regFirstNameRef}
              regLastNameRef={regLastNameRef}
              regEmailRef={regEmailRef}
              regPasswordRef={regPasswordRef}
              onSubmit={handleRegSubmit}
            />
          </Route>
        </Switch>

        {formContent}
      </div>
      <div className="footer">
        <p>
          © {year} Rob Lahoda •{" "}
          <a href="mailto:rob@roblahoda.com">rob@roblahoda.com</a> •{" "}
          <a href="https://www.roblahoda.com">roblahoda.com</a>
        </p>
      </div>
    </main>
  );
}

const mapStateToProps = state => {
  return {
    sessionToken: state.sessionToken,
    authToken: state.authToken,
    loginMsg: state.userInfo.loginMessage,
    loginStatus: state.ui.loginMessage,
  };
};

export default withRouter(connect(mapStateToProps)(Login));
