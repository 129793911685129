export const REGISTER_USER = "[user] register user";
export const SUCCESS_REGISTER_USER = "[user] success register user";
export const DELETE_USER = "[user] delete user";
export const LOGIN_USER = "[user] login user";
export const LOGOUT_USER = "[user] log out user";
export const PROCESS_USER_LOGIN = "[user] process logged in user";
export const GET_CURRENT_USER_INFO = "[user] get current user info";
export const PROCESS_CURRENT_USER_INFO = "[user] process current user info";
export const ADD_CURRENT_USER_INFO = "[user] add current user info";
export const GET_CURRENT_USER_ID = "[user] get current user id";
export const PROCESS_CURRENT_USER_ID = "[user] process current user id";

export const loginUser = (info, history) => ({
  type: LOGIN_USER,
  payload: info,
  history
});
export const logoutUser = () => ({
  type: LOGOUT_USER
});

export const registerUser = (info, history) => ({
  type: REGISTER_USER,
  payload: info,
  history
});

export const successRegisterUser = () => ({
  type: SUCCESS_REGISTER_USER
});

export const processUserLogin = data => ({
  type: PROCESS_USER_LOGIN,
  payload: data
});

export const deleteUser = () => ({
  type: DELETE_USER
});
export const getCurrentUserInfo = () => ({
  type: GET_CURRENT_USER_INFO
});
export const processCurrentUserInfo = () => ({
  type: PROCESS_CURRENT_USER_INFO
});
export const addCurrentUserInfo = () => ({
  type: ADD_CURRENT_USER_INFO
});
export const getCurrentUserId = () => ({
  type: GET_CURRENT_USER_ID
});
export const processCurrentUserId = () => ({
  type: PROCESS_CURRENT_USER_ID
});
