import React from "react";

const SvgThinArrowKnockoutIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M50.21 0C22.6 0 .21 22.39.21 50s22.39 50 50 50 50-22.39 50-50-22.39-50-50-50zm.01 79.74h-.02L12.82 42.35l8.07-8.07 29.33 29.33 29.33-29.33 8.07 8.07-37.4 37.39z" />
  </svg>
);

export default SvgThinArrowKnockoutIcon;
