import React from "react";

/*
Props:
leftIcon = component
title = string
rightIcon = component
rightIconRotated = bool
onClick = function
*/

function SidebarMainLinkExternal(props) {
  const {
    leftIcon,
    title,
    rightIcon,
    link,
    leftIconStyles,
    rightIconStyles,
    additionalStyles = ""
  } = props;
  return (
    <a
      data-test="sidebar-main-link"
      className={
        "sidebar-main-button__main-container cursor-pointer link-button " +
        additionalStyles
      }
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="sidebar-main-button__title-container">
        <div
          data-test="left-icon"
          className={
            "sidebar-main-button__left-icon-container " + leftIconStyles
          }
        >
          {leftIcon}
        </div>
        <span
          className="sidebar-main-button__title text-large"
          data-test="title"
        >
          {title}
        </span>
      </div>
      <div
        data-test="right-icon"
        className={
          "sidebar-main-button__right-icon-container " + rightIconStyles
        }
      >
        {rightIcon}
      </div>
    </a>
  );
}
export default SidebarMainLinkExternal;
