import React from "react";

/*
Props:
leftIcon = component
title = string
rightIcon = component
primaryOnClick = function
secondaryOnClick = function
*/

function SidebarSubButton(props) {
  const {
    leftIcon,
    title,
    rightIcon,
    primaryOnClick,
    secondaryOnClick,
    leftIconStyles,
    rightIconStyles,
    additionalStyles = "",
    calloutButton,
  } = props;

  const calloutStyle = calloutButton
    ? " sidebar-sub-button__main-container--callout-button"
    : "";

  const mainStyles =
    "sidebar-sub-button__main-container " + additionalStyles + calloutStyle;

  return (
    <div className={mainStyles} data-test="main-container">
      <button
        data-test="sidebar-secondary-button"
        className="sidebar-sub-button__primary-button cursor-pointer"
        onClick={primaryOnClick}>
        <div
          className={
            "sidebar-sub-button__left-icon-container " + leftIconStyles
          }
          data-test="left-icon">
          {leftIcon}
        </div>
        <span className="sidebar-sub-button__title" data-test="title">
          {title}
        </span>
      </button>
      {secondaryOnClick ? (
        <button
          data-test="right-icon"
          className={
            "sidebar-sub-button__secondary-button cursor-pointer " +
            rightIconStyles
          }
          onClick={secondaryOnClick}>
          <span className="visually-hidden">Delete {title} Map view</span>
          {rightIcon}
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export default SidebarSubButton;
