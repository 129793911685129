import {
  INSERT_MAPVIEW,
  REMOVE_MAPVIEW,
  LOAD_MAPVIEWS,
} from "../actions/mapViewsActions";

function mapViews(state = (mapViews = {}), action) {
  switch (action.type) {
    case INSERT_MAPVIEW:
      return {
        ...state,
        [action.payload.uid]: {
          title: action.payload.title,
          lat: action.payload.lat,
          lng: action.payload.lng,
          zoomLevel: action.payload.zoomLevel,
          uid: action.payload.uid,
          revision: action.payload.revision,
        },
      };

    case REMOVE_MAPVIEW:
      const newMapViews = { ...state };
      delete newMapViews[action.meta.uid];
      return { ...newMapViews };

    case LOAD_MAPVIEWS:
      return action.payload;

    default:
      return state;
  }
}
export default mapViews;
