import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/userActions";

import { FormattedMessage } from "react-intl";
import { persistor } from "../../store";
import { controller } from "../../redux/middleware/apiMiddleware";
/**
 * Function to redirect to login. This is for the logout process so that when the redux store is wiped, the user is then redirected to the login page the same way they would be via php
 */

function RedirectToLogin(props) {
  useEffect(() => {
    // stop all active api fetch calls
    // controller.abort(); <- this is not in use because it causes an issue where no further fetch calls work until the page is refreshed.
    // pause any data going to the local storage persist store
    persistor.pause();
    // dispatch the action to clear the redux store
    props.dispatch(logoutUser());
    // flush any data that's being written to the local persist storage
    persistor.flush().then(() => {
      // when the flush is done purge the local store
      return persistor.purge();
    });
    // give everything 1.5 sec to happen then click the link to redirect the browser to the login screen
    setTimeout(() => {
      props.history.push("/login");
    }, 1500);
  }, []); // eslint-disable-line

  return (
    <React.Fragment>
      <div className="flex-column text-center">
        <h1>
          <FormattedMessage
            id="redirect.loggingouttext"
            defaultMessage="Logging you out"
            description="Opens editing something"
          />
        </h1>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(RedirectToLogin);
