import {
  GET_SESSIONTOKEN,
  ADD_SESSIONTOKEN,
  CHECK_SESSIONTOKEN
} from "../actions/sessionTokenActions";
import { apiRequest } from "../actions/apiActions";
import { getSessionToken } from "../actions/sessionTokenActions";

const getSessionTokenMdl = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === GET_SESSIONTOKEN) {
    const meta = {
      method: "GET",
      url: "session/token",
      body: false,
      onSuccess: ADD_SESSIONTOKEN,
      onError: "",
      endPointType: "restSessionToken"
    };
  const body = {}
    dispatch(apiRequest(body, meta));
  }
};

const checkSessionToken = store => next => action => {
  next(action);
  if (action.type === CHECK_SESSIONTOKEN) {
    const sessionToken = store.getState().sessionToken;

    if (!sessionToken) {
      store.dispatch(getSessionToken());
    }
  }
};

export const sessionTokenMdl = [getSessionTokenMdl, checkSessionToken];
