import React from "react";
import L from "leaflet";
import { connect } from "react-redux";
import { Marker } from "react-leaflet";
import { iconObj } from "../../assets/MapPointIcons";
import MapMarkerPopup from "./MapMarkerPopup";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("../../assets/mapIcons/starIcon.svg"),
  iconUrl: require("../../assets/mapIcons/starIcon.svg"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

function MarkerWrapper(props) {
  const { lat, lng, category } = props.point;
  const position = [lat, lng];
  let pointIcon = {};
  const categories = props.categories;
  if (category) {
    const cat = categories[category];
    if (cat) {
      const categoryName = cat.name;
      if (iconObj[categoryName]) {
        pointIcon = iconObj[categoryName];
      }
    }
  }

  const markerPopup = (
    <MapMarkerPopup
      marker={props.point}
    />
  );
  function markerDisplay(params) {
    if (pointIcon.icon) {
      return (
        <Marker position={position} icon={pointIcon.icon}>
          {markerPopup}
        </Marker>
      );
    } else {
      return <Marker position={position}>{markerPopup}</Marker>;
    }
  }

  return <React.Fragment>{markerDisplay()}</React.Fragment>;
}

const mapStateToProps = (state, ownProps) => {
  const point = state.mapPoints[ownProps.pointId];
  return {
    categories: state.categories,
    point,
    points: state.mapPoints
  };
};

export default connect(mapStateToProps)(MarkerWrapper);
