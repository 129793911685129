import React from "react";
import {
  REGISTER_USER,
  SUCCESS_REGISTER_USER,
  DELETE_USER,
  GET_CURRENT_USER_INFO,
  GET_CURRENT_USER_ID,
  PROCESS_CURRENT_USER_INFO,
  PROCESS_CURRENT_USER_ID,
} from "../actions/userActions";
import { apiRequest } from "../actions/apiActions";
import { showLoading, registerMsg } from "../actions/uiActions";
import { FormattedMessage } from "react-intl";

const registerUser = store => next => action => {
  next(action);
  if (action.type === REGISTER_USER) {
    const token = store.getState().authToken.authToken;
    const sessionToken = store.getState().sessionToken;

    let body = {
      data: {
        name: [{ value: action.payload.username }],
        mail: [{ value: action.payload.username }],
        pass: [{ value: action.payload.password }],
        field_first_name: [{ value: action.payload.firstName }],
        field_last_name: [{ value: action.payload.lastName }],
      },
    };
    const meta = {
      method: "POST",
      url: "user/register",
      body: true,
      bodyType: "json",
      endPointType: "register",
      onSuccess: SUCCESS_REGISTER_USER,
      onError: "",
      sessionToken: sessionToken,
      history: action.history,
    };
    store.dispatch(showLoading());
    store.dispatch(apiRequest(body, meta));
  }
};

const deleteUser = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === DELETE_USER) {
    dispatch(showLoading());
  }
};

const successRegisterUser = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === SUCCESS_REGISTER_USER) {
    const successMessageText = (
      <FormattedMessage
        id="userMdl.successMessageText"
        defaultMessage="Successfully Registered! Please log in with your credentials."
        description=""
      />
    );
    action.meta.history.push("/login");
    dispatch(registerMsg(successMessageText));
  }
};

const getCurrentUserInfo = store => next => action => {
  next(action);
  if (action.type === GET_CURRENT_USER_INFO) {
    const sessionToken = store.getState().sessionToken;
    const token = store.getState().authToken.authToken;
    const body = null;
    const meta = {
      method: "GET",
      url: "/user/",
      onError: "",
      endPointType: "rest",
      authToken: token,
      onSuccess: PROCESS_CURRENT_USER_INFO,
      sessionToken,
      body: false,
    };

    store.dispatch(apiRequest(body, meta));
  }
};
const getCurrentUserId = store => next => action => {
  next(action);
  if (action.type === GET_CURRENT_USER_ID) {
    const token = store.getState().authToken.authToken;
    const body = null;
    const meta = {
      method: "GET",
      url: "jsonapi",
      onError: "",
      endPointType: "json",
      authToken: token,
      onSuccess: PROCESS_CURRENT_USER_ID,

      body: false,
    };

    store.dispatch(apiRequest(body, meta));
  }
};

export const userMdl = [
  registerUser,
  deleteUser,
  successRegisterUser,
  getCurrentUserInfo,
  getCurrentUserId,
];
