export const API_REQUEST = "[api] API Request";
export const API_QUEUE_ADD = "[api] Add a request to the API Queue";
export const API_QUEUE_UPDATE = "[api] update the API Queue";
export const API_QUEUE_SUBMIT = "[api] submit a request from the API Queue";

export const apiRequest = (body, meta) => ({
  type: API_REQUEST,
  payload: body,
  meta: meta
});
export const apiQueueAdd = request => ({
  type: API_QUEUE_ADD,
  request
});
export const apiQueueUpdate = array => ({
  type: API_QUEUE_UPDATE,
  payload: array
});
export const apiQueueSubmit = request => ({
  type: API_QUEUE_SUBMIT,
  request
});
