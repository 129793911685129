import React from "react";
import L from "leaflet";
import { connect } from "react-redux";
import { Marker } from "react-leaflet";
import { iconObj } from "../../assets/MapPointIcons";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: "/mapIcons/starIcon.svg",
  iconUrl: "/mapIcons/starIcon.svg",
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

function EditMarkerWrapper(props) {
  const category = props.point.category;
  const { lat, lng } = props.editPosition;
  const position = [lat, lng];
  let pointIcon = {};
  const categories = props.categories;
  if (category) {
    const cat = categories[category];
    if (cat) {
      const categoryName = cat.name;
      if (iconObj[categoryName]) {
        pointIcon = iconObj[categoryName];
      }
    }
  }

  function markerDisplay(params) {
    if (pointIcon.icon) {
      return <Marker position={position} icon={pointIcon.icon}></Marker>;
    } else {
      return <Marker position={position}></Marker>;
    }
  }

  return <React.Fragment>{markerDisplay()}</React.Fragment>;
}

const mapStateToProps = (state, ownProps) => {
  let point = {};
  if (ownProps.pointId) {
    point = state.mapPoints[ownProps.pointId];
  }
  return {
    categories: state.categories,
    point,
    points: state.mapPoints
  };
};

export default connect(mapStateToProps)(EditMarkerWrapper);
