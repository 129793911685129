import {
  SET_LAST_CLICKED_LOCATION,
  SET_EDIT_POINT_LOCATION,
  SET_EDIT_POINT_ACTIVE
} from "../actions/editItemActions";

function editItemInformation(
  state = {
    lat: null,
    lng: null,
    point: {
      editPoint: false,
      editLat: null,
      editLng: null,
      editPointId: null
    }
  },
  action
) {
  switch (action.type) {
    case SET_LAST_CLICKED_LOCATION:
      return {
        ...state,
        lat: action.payload.lat,
        lng: action.payload.lng
      };

    case SET_EDIT_POINT_LOCATION:
      return {
        ...state,
        point: {
          ...state.point,
          editLat: action.payload.lat,
          editLng: action.payload.lng,
          editPointId: action.payload.pointId
        }
      };

    case SET_EDIT_POINT_ACTIVE:
      return {
        ...state,
        point: {
          ...state.point,
          editPoint: action.payload
        }
      };

    default:
  }

  return state;
}

export default editItemInformation;
