import React from "react";
import { Link } from "react-router-dom";
/*
Props:
leftIcon = component
title = string
rightIcon = component
link = string
*/

function SidebarSubLink(props) {
  const {
    leftIcon,
    rightIcon,
    title,
    leftIconStyles,
    rightIconStyles,
    additionalStyles = "",
    link
  } = props;

  return (
    <Link
      data-test="sidebar-sub-link"
      className={
        "sidebar-sub-button__main-container link-button " + additionalStyles
      }
      to={link}
    >
      <div className="sidebar-sub-link__left-container">
        <div
          className={
            "sidebar-sub-button__left-icon-container " + leftIconStyles
          }
          data-test="left-icon"
        >
          {leftIcon}
        </div>
        <span className="sidebar-sub-button__title" data-test="title">
          {title}
        </span>
      </div>
      <div
        className={"sidebar-sub-link__right-icon-container " + rightIconStyles}
        data-test="right-icon"
      >
        {rightIcon}
      </div>
    </Link>
  );
}

export default SidebarSubLink;
