import { API_REQUEST } from "../actions/apiActions";
import { hideLoading } from "../actions/uiActions";
export const controller = new AbortController();
const { signal } = controller;

// let rootUrl = "http://myvacationmap.lndo.site/";
// let rootUrl = "https://dev-my-vacation-map.pantheonsite.io/";
let rootUrl = "https://test-my-vacation-map.pantheonsite.io/";

function handleResponse(response) {
  if (response.status === '204' || response.status === 204) {
  return true
} else {

  let contentType = response.headers.get("content-type");
  
  if (contentType.includes("application/json")) {
    return handleJSONResponse(response);
  } else if (
    contentType.includes("text/html") ||
    contentType.includes("text/plain")
  ) {
    return handleTextResponse(response);
  } else if (contentType.includes("application/vnd.api+json")) {
    return response.json().then(function (data) {
      return data;
    });
  } else {
    // Other response types as necessary. I haven't found a need for them yet though.
    throw new Error(`Sorry, content-type ${contentType} not supported`);
  }
}

}

function handleJSONResponse(response) {
  return response.json().then(json => {
    if (response.ok) {
      return json;
    } else {
      return Promise.reject(
        Object.assign({}, json, {
          status: response.status,
          statusText: response.statusText,
        })
      );
    }
  });
}
function handleTextResponse(response) {
  return response.text().then(text => {
    if (response.ok) {
      return text;
    } else {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        err: text,
      });
    }
  });
}

const api = ({ dispatch }) => next => action => {
  if (action.type === API_REQUEST) {
    const {
      method,
      url,
      onSuccess,
      onError,
      authToken,
      sessionToken,
      body,
      bodyType,
      endPointType,
      fullUrl,
    } = action.meta;
    let messageBody = null;
    if (body) {
      if (bodyType && bodyType === "json") {
        messageBody = JSON.stringify(action.payload);
      } else {
        messageBody = action.payload;
      }
    }
    // console.log(action.payload);
    // console.log(JSON.stringify(action.payload));

    // console.log(messageBody);

    let headers;
    if (endPointType === "json") {
      headers = {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: "Bearer " + authToken,
      };
    } else if (endPointType === "rest") {
      headers = {
        "Content-Type": "application/json",
        "X-CSRF-Token": sessionToken,
        Authorization: "Bearer " + authToken,
      };
    } else if (endPointType === "register") {
      headers = {
        "Content-Type": "application/json",
        "X-CSRF-Token": sessionToken,
      };
    } else if (
      endPointType === "restSessionToken" ||
      endPointType === "restLogout"
    ) {
      headers = {
        "Content-Type": "application/json",
      };
    }

    let endPoint = rootUrl + url;

    if (fullUrl) {
      endPoint = url;
    }

    fetch(endPoint, {
      signal,
      method: method,
      headers,
      body: messageBody,
    })
      .then(handleResponse)
      .then(
        data => dispatch({ type: onSuccess, payload: data, meta: action.meta })
        // This is set up to return all of the information sent for the original api call
        // this allows passing additional metadata that might be used in a later action or
        // middleware. By returning the entire meta object, this allows anything to be passed
        // through without having to continually expand the api middleware for every use case.
      )
      .catch(error => {
        console.log(error);

        dispatch({
          type: onError,
          payload: error,
          meta: action.meta,
          originalPayload: action.payload,
        });
      });
  }
  return next(action);
};
export default api;
