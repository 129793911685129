import React from "react";

const SvgUserKnockoutIcon = props => (
  <svg
    viewBox="0 0 100 100"
    {...props}
    fill="currentColor"
  >
    <path d="M50.2 0C22.6 0 .2 22.4.2 50s22.4 50 50 50 50-22.4 50-50-22.4-50-50-50zM50 8.4c11 0 19.9 8.9 19.9 19.9 0 11-8.9 19.9-19.9 19.9s-19.9-8.9-19.9-19.9c0-11 8.9-19.9 19.9-19.9zm.2 86.6c-13.5 0-25.7-6-33.9-15.5C18.6 62.8 32.8 50 50 50c17.3 0 31.6 13.1 33.8 29.9C75.5 89.2 63.6 95 50.2 95z" />
  </svg>
);

export default SvgUserKnockoutIcon;
