import {
  ADD_AUTHTOKEN,
  REFRESHING_AUTHTOKEN,
  FINISH_REFRESHING_AUTHTOKEN
} from "../actions/authActions";

function authToken(
  state = {
    tokenRefreshing: false,
    tokenExpiration: 0,
    refreshTokenExpiration: 0,
    authToken: "",
    refreshToken: "",
    loggedIn: false
  },
  action
) {
  switch (action.type) {
    case ADD_AUTHTOKEN:
      return {
        ...state,
        authToken: action.payload.authToken,
        loggedIn: action.payload.loggedIn,
        refreshToken: action.payload.refreshToken,
        tokenExpiration: action.payload.tokenExpiration,
        refreshTokenExpiration: action.payload.refreshTokenExpiration,
        tokenRefreshing: action.payload.tokenRefreshing
      };

    case REFRESHING_AUTHTOKEN:
      return {
        ...state,
        tokenRefreshing: true
      };

    case FINISH_REFRESHING_AUTHTOKEN:
      return {
        ...state,
        tokenRefreshing: false
      };
    default:
  }

  return state;
}

export default authToken;
